.switcher {
    position: relative;
    height: 30px;
    color: $color-grey;
    .currency-text {
        font-size: 16px;
        line-height: 30px;
        width: 30px;
        height: 30px;
        position: relative;
        z-index: 3;
        text-align: center;
        float: left;
        font-family: $fontfamilyheaders;
        cursor: pointer;
    
        &.active {
            color: #45B61D;
            font-weight: bold;
        }
        &:nth-child(2) {
            margin-right: 2px;
            position: relative;
            &:after {
                float: left;
                content: "";
                position: absolute;
                top: -1px;
                right: -3px;
                height: 100%;
            }
        }
    }
    .checkbox-slider {
        display: none;

        &.currency:checked+label:before {
            background-color: #b9b8b8;
            transition: all .2s;
        }
        &.currency+label {
            padding: 0 0 0 41px;
        }

        &+label {
            color: $color-grey;
            cursor: pointer;
            position: relative;
            padding: 0 0 0 50px;
        }

        &+label:before {
            position: absolute;
            content: "";
            width: 35px;
            height: 14px;
            background-color: #dbdbdb;
            left: 0px;
            top: 8px;
            border-radius: 25px;
            transition: all .2s;
        }

        &+label:after {
            position: absolute;
            content: "";
            width: 20px;
            height: 20px;
            background-color: #f1f1f1;
            left: -1px;
            top: 4px;
            border-radius: 50%;
            box-shadow: 1px 1px 2px rgba(0, 0, 0, .2);
            transition: all .2s;
        }
        
        &:checked+label:before {
            background-color: $color-olive;
            transition: all .2s;
        }

        &:checked+label:after {
            box-shadow: -1px 1px 2px rgba(0, 0, 0, .2);
            left: auto;
            left: 16px;
            transition: all .2s;
        }

        &:disabled+label:after {
            background-color: #b9b8b8;
        }

        &:disabled+label {
            cursor: not-allowed;
        }
    }
    &.wrapper-measure {
        &.km .km, 
        &.mile .mile {
            font-weight: bold;
        }
    }
}
