.form-auth {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 520px;
    max-height: 580px;
    background: $color-solitude;
    .bottom-container {
        .social-icon {
            font-size: 44px;
            line-height: 44px;
        }
        .description {
            font-size: 14px;
            text-align: center;
        }
    }

    &[data-currentform="signup"] .form-auth-tab-content.signup,
    &[data-currentform="signin"] .form-auth-tab-content.signin,
    &[data-currentform="forgotpassword"] .form-auth-tab-content.forgotpassword,
    &[data-currentform="loginerror"] .form-auth-tab-content.loginerror {
        display: flex;
    }
    .form-auth-tab-content {
        display: none;
        flex: 1 1 auto;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 20px 20px;
        .forgotpassword,
        .forgotpassword-no {
            color: #000;
            font-size: 14px;
            .cross  {
                @media (max-width: $mobile) {
                    position: absolute;
                }
            }
        }
        .input-container {
            display: grid;
            margin-bottom: 20px;
            grid-row-gap: 20px;
            p{
				font-family: $fontfamily;
				font-size: 15px;
			}
            &.with-error {
                grid-row-gap: 0px;
                .input-row {
                    margin-bottom: 20px;
                    &.error {
                        margin-bottom: 5px;
                    }
                }
                .button {
                    margin-top: 20px;
                }
            }
        }
        .social-btn-login {
            display: flex;
            justify-content: center;
            & > a:not(:last-child) {
                margin-right: 20px;
            }
            .twitter {
                svg {
                    color: #1B95E0;
                    fill: #1B95E0;
                }
            }
        }
        .bottom-container {
            text-align: center;
        }
        &.signin {
            flex: inherit;
            .bottom-container {
                margin-top: 60px;
            }
        }
        .checkbox-row {
            .error-label {
                display: none;
                color: #fff;
                align-items: center;
                svg {
                    position: initial;
                    color: #fff;
                    fill: #fff;
                    font-size: 16px;
                    margin-right: 8px;
                }
            }
            &.error {
                .error-label {
                    display: flex;
                }
            }
        }
    }
}
.promo-login-wrapper {
    .form-auth {
        position: static;
        float: left;
        height: auto;
        min-height: 510px;
    }
}
.login-message {
    margin-bottom: 10px;
}
