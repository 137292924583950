textarea:not([class]) {
    display: block;
    padding: 20px;
    border: 1px solid $color-grey;
    border-radius: 2px;
    background-color: #fdfdfd;
    color: $color-night-rider;
    font-family: $fontfamily;
    font-size: 1rem;
    line-height: 24px;
    resize: none;
    min-height: 70px;
    &:focus {
        outline: none;
        background-color: #fff;
    }
}

.textarea-row {
    &.not-changed {
        margin-top: 20px;
        textarea:not([class]) {
            border: none;
            background: $color-solitude;
            cursor: initial;
            & + label {
                top: -7px;
                background-color: initial;
                color: $color-grey;
                font-size: 12px;
            }
        }
    }
    &.with-attachment {
        border: 1px solid $color-grey;
        textarea {
            border: 0;
        }
        .block-attach-files {
            display: flex;
            align-items: center;
            height: 48px;
            padding: 0 16px;
            border-top: 1px solid $color-grey;
            background-color: $color-solitude;
            &:hover {
                cursor: pointer;
                span {
                    text-decoration: underline;
                }
            }
        }
        &.error {
            border-color: $color-red;
            .error-label {
                background-color: $color-solitude;
            }
        }
    }
}
