@mixin uncollapse(){
	content: " ";
	display: block; 
	height: 0; 
	clear: both;	
}

*:not(html):not(body) {
	&::-webkit-scrollbar { width: 5px; }
	&::-webkit-scrollbar-track { background: transparent; }
	&::-webkit-scrollbar-thumb { background: $color-night-rider; }
}

// @keyframes onAutoFillCancel {
// 	from {
// 		background: black;
// 	}
// 	to {
// 		background: red;
// 	}
// }
