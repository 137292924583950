@keyframes fa-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

@keyframes grayredtwinkle {
    0% {
        background-color: $color-solitude;
    }
    50% {
        background-color: #ffc7c7;
    }
    100% {
        background-color: $color-solitude;
    }
}

@keyframes wave {
    0%, 
    24%, 
    78%, 
    100% {
        transform: translate(0, 0);
    }
    56% {
        transform: translate(0, -5px);
    }
}

@keyframes autofill {
    to {
        background-color: #fdfdfd;
        color: $global-color-font;
    }
}
@keyframes gradient {
	0% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 50% 0%;
	}
}