header {
    @media (max-width: $mobile) {
        padding-bottom: 45px;
    }
    .header-logo-section {
        position: relative;
        background-color: #fff;
        z-index: 120;
        @media (max-width: $tablet) {
            display: none;
        }
        .row-top { 
            display: flex;
            position: relative;
            align-items: center;
            justify-content: space-between;
            height: 44px;
            .block-logo {
                padding-left: 10px;
                img {
                    max-width: 238px;
                }
            }
            .block-sign-out {
                display: flex;
                position: absolute;
                right: 5px;
                align-items: center;
                font-family: $fontfamilyheaders; 
                font-size: 14px;
                font-weight: 500;
                line-height: 18px; 

                .user-name {
                    margin-right: 30px;
                    color: $color-grey;
                    text-transform: capitalize;
                }

                .sign-out {
                    display: flex;
                    align-items: center;
                    padding: 3px 4px;
                    border-radius: 2px;
                    color: $color-grey;
                    text-decoration: none;
                    user-select: none;      

                    img {
                        margin-left: 5px;
                        &.out-black {
                            display: none;
                        }
                    }

                    &:hover {
                        background: $color-solitude;
                        color: #000;
                        img {
                            &.out-black {
                                display: inline-block;
                            }
                            &.out-gray {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        
    }
	.header-menu-section {
        transition: box-shadow .3s cubic-bezier(.25, .8, .25, 1);
        box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
        @extend .loading;
        position: relative;
        width: 100%;
        border-top: 1px solid $color-solitude;
        cursor: default !important;
        opacity: 1;
        z-index: 110;
        &:before {
            top: 350px !important;
        }
        @media(max-width: $tablet) {
            border-top: 0;
        }
        .menu-sticky-header {
            width: 100%;
            background: #fff;
            @media(max-width: $mobile) {
                transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
                box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.15);
                position: fixed;
                top: 0;
            }
            .container {
                @media (max-width: $tablet) {
                    margin: 0;
                }
            }
            &.fixed {
                transition: box-shadow .3s cubic-bezier(.25, .8, .25, 1);
                box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
                position: fixed;
                top: 0;
            }        
            &.no-fixed-supported {
                width: 100%;
            }
            .menu-sign {
                .menu-sign-link {
                    display: block;
                }
                &.active {
                    .nav-sub {
                        display: block;
                        width: 360px;
                    }
                    .menu-sign-link {
                        background: $color-solitude;
                        color: $global-color-font;
                    }
                }
            }
        }
    }
}


// Header static

// .header-static-wrapper {
//     position: sticky;
//     top: 0;
//     z-index: 122;
// }

// .header-static {
//     position: sticky;
//     top: 0;
//     display: flex;
//     align-items: center;
//     background: #FFFFFF;
//     padding: 0px 24px;
//     box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.15);
//     margin-bottom: 48px;
//     z-index: 5;

//     @media screen and (max-width: 1000px) {
//         padding: 0px;
//     }
// }

// .header-static__list {
//     display: flex;
//     align-items: center;
//     margin: 0px;
//     padding: 0px;

//     @media screen and (max-width: 1000px) {
//         display: none;
//     }
// }

// .header-static__item {
//     display: flex;

//     .link {
//         font-family: "Montserrat";
//         font-size: 18px;
//         font-weight: 600;
//         line-height: 40px;
//         text-transform: uppercase;
//         color: #333333;
//         padding: 20px 16px;
//         text-decoration: none;
//     }
// }

// .header-static__logo {
//     display: flex;
//     align-items: flex-start;
//     padding-right: 32px;
//     margin-right: 16px;
//     border-right: 1px solid #000000;

//     .mobile {
//         margin-top: -1px;
        
//         @media screen and (min-width: 1375px) {
//             margin-right: 5px;
//         }
//     }

//     .desktop {
//         width: 209px;
//         height: 40px;
//     }

//     @media screen and (max-width: 1375px) {
//         border-right: none;
//         padding-right: 0px;

//         .desktop {
//             display: none;
//         }

//         .mobile {
//             display: block;
//         }
//     }

//     @media screen and (max-width: 1000px) {
//         border-right: none;
//         padding-right: 0px;

//         .mobile {
//             display: none;
//         }

//         .desktop {
//             display: flex;
//             width: 140px;
//             height: 16px;
//         }
//     }
// }

// .header-static__burger-btn {
//     display: none;
//     flex-shrink: 0;
//     width: 44px;
//     height: 44px;
//     padding: 15px 13px;
//     margin-right: 10px;
//     background: transparent;
//     border: none;

//     @media screen and (max-width: 1000px) {
//         display: flex;
//     }

//     .header-static__burger-btn-container {
//         position: relative;
//         display: block;
//         width: 18px;
//         height: 15px;

//         &::before,
//         &::after,
//         .decor-elem {
//             position: absolute;
//             top: 0px;
//             left: 0px;
//             width: 18px;
//             height: 3px;
//             background-color: #993333;
//         }
    
//         &::before,
//         &::after {
//             content: "";
//             transition: transform .2s;
//         }
    
//         &::after {
//             top: 12px;
//         }
    
//         .decor-elem {
//             top: 6px;
//             transition: opacity .2s, transform .2s;
//         }
//     }
// }