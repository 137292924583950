
.static-horizontal-girls-wrap {
    h2 {
        margin-bottom: 20px;
        width: 70%;
    }
    .index-page & {
        .static-horizontal-girls {
            .item {
                width: 240px !important;
            }
        }
    }
	.static-horizontal-girls {
		margin-bottom: 30px;
        margin-top: 30px;
		.item {
			width: 244px;
            padding-left: 4px;
            @media (max-width: $mobile) {
				margin: 0 auto;
                width: auto !important;
			}
            
			.girl-photo-wrap-container {
				// max-height: 420px;
				// height: 100%;
				.label {
					// left: 4px;
				}
			}
            .chat-video .girl-photo-wrap {
                height: 360px;
                min-height: 360px;
                max-height: 360px;
            }
		}		
	}
}
.block-panoram-slider {
    .owl-prev,
    .owl-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        user-select: none;
        svg {
            color: #fff;
            font-size: 28px;
        }
    }

    .owl-prev {
        left: 5px;
    }

    .owl-next {
        right: 5px;
    }
    @media (max-width: $mobile) {
        .owl-item img {
            width: auto;
        }
    }
}


// вертикальная карусель на статике, должна быть переверстана
.girl_vertical_carousel {
    float: left;
    width: 100%;
    height: 1115px;
    padding: 0px;
    text-align: center;
    margin-bottom: 0px;
    overflow: hidden;
    padding-left: 5px;
    .vertical-girls-carousel {
        position: relative;
        // overflow: hidden;
        float: left;
        width: 100%;
        ul {
            position: absolute;
            list-style: none;
            width: 100%;
            padding: 0;
            margin: 0;
            li {
                float: left;
                width: 100%;
                position: relative;
                margin-bottom: 10px;
                img {
                    float: left;
                    width: 100%;
                    padding-top: 0;
                    @media (max-width: $mobile) {
                        width: auto;
                        float: none;
                        display: block;
                        margin: 0 auto;
                    }
                }
                .name {
                    float: left;
                    width: 100%;
                    position: absolute;
                    left: 0px;
                    bottom: 0px;
                    color: #fff;
                    text-align: center;
                    background: rgba(0, 0, 0, .7);
                    padding: 5px 0;
                }
                // .label {
                //     float: left;
                //     color: #fff;
                //     font-size: 11px;
                //     top: 15px;
                //     left: 0;
                //     padding: 4px 6px;
                //     position: absolute;
                //     line-height: 12px;
                //     font-weight: bold;
                //     &.soon {
                //         background-color: $color-selective-yellow;
                //         color: $color-night-rider;
                //     }
                //     &.online {
                //         background-color: $color-olive;
                //     }
                // }
            }
        }
    }
    .vert-carousel-nav {
        display: none;
    }
    .vert-carousel-more-ladies {
        display: none;
        float: right;
        color: $color-grey;
    }
}

.rel-fav-carousel-wrap {
	padding: 20px;
	.navi.inbox { 
		padding: 0px;
	}
}

.defult-arrow-owl {
    position: relative;
    padding: 0!important;
    .carousel-title {
        position: absolute;
        top: -30px;
        @media (max-width:$mobile) {
            top: -25px;
        }
    }
    .owl-carousel {
        display: flex;
        flex-direction: column-reverse;
        .owl-nav {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 20px;
            position: absolute;
            top: -50px;
            right: 0;
            @media (max-width:$mobile) {
                padding: 0 10px;
            }
            .owl-prev {
                margin-right: 16px;
            }
            .owl-next, 
            .owl-prev {
                position: static;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: none;
                border: 1px solid $color-grey;
                &.disabled {
                    pointer-events: none;
                    opacity: .5;
                }
                svg {
                    position: static;
                    font-size: 14px;
                    transform: none;
                }
            }
            img{
                width: 14px;
            }
        }
    }
}