@import '../variables';
a.button,
button.button {
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 44px;
    padding: 15px 16px;
    border: 0;
    border-radius: 2px;
    color: $global-color-font;
    font-family: $fontfamilyheaders;
    font-size: 16px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    overflow: hidden;
    user-select: none;
    &.disabled {
        opacity: .5;
        pointer-events: none;
    }
    &.button-small {
        height: 32px;
        padding: 9px 11px;
        font-size: 14px;
        .container {
            font-size: 14px;
        }
    }
    &.button-block {
        display: flex;
        flex: 1 1 auto;
        width: 100%;
        max-width: 100%;
    }
    &.hide{
        
        display: none;
    }
    // это хак, все иконки должны быть одного размера - 22 пикселя
    &[class*='icon-left'],
    &[class*='icon-right'] {
        white-space: nowrap;
        svg {
            font-size: 18px;
        }
    }
    &[class*='icon-left'] {
        svg {
            margin-right: 8px;
        }
    }
    &[class*='icon-right'] {
        flex-direction: row-reverse;
        svg {
            margin-left: 8px;
        }
    }
    &--green{
        background-color: $color-olive;
        color: #fff;
    }
    &--black{
        background-color: #000000;
        color: #fff;
        &.hovered:before{
            background-color: rgba(255, 255, 255, 0.3) !important;
        }
        svg {
            color: #fff;
            fill: #fff;
        }
    }
    &--grey{
        background: #F1F3F5;
        color: #000;
    }
    &--full-w{
        width: 100%;
    }
    &--black{
        background-color: #000000;
        color: #fff;
        &:before{
            content: '';
        }
        &.hovered{
            &:before{
                background-color: rgba(255, 255, 255, 0.5);
            }
        }
    }
    &.button-apple-pay {
        width: 100%;
        svg {
            width: 55px;
            height: 44px;
        }
    }
}

@each $key, $value in $theme-colors {
    a.button-#{$key},
    button.button-#{$key} {
        background-color: $value;

        @if $key == 'warning' or $key == 'light' {
            color: $global-color-font;
        } @else {
            color: #fff;
        }
    }

    a.button-transparent-#{$key},
    button.button-transparent-#{$key} {
        background-color: transparent;
        @if $key == 'dark' {
            color: $global-color-font;
        } @else {
            color: $value;
        }
        .processed-loader .dots span {
            background-color: $value;
        }
    }

    a.button-bordered-#{$key},
    button.button-bordered-#{$key} {
        border: 1px solid $value;
        background-color: transparent;
        color: $value;
        @if $key == 'light' {
            &:active,
            &:hover,
            &.hovered {
                background-color: transparent;
                color: #fff;
                &:before {
                    background-color: transparent;
                }
            }
        } @else {
            &:active, 
            &:hover, 
            &.hovered {
                background-color: $value;
                color: #fff;
                &:before {
                    background-color: transparent;
                }
            }
        }
    }
    a.button-bordered-dark-bg,
    button.button-bordered-dark-bg {
        border: 1px solid #858585;
        color: #858585;
        background: #F1F3F5;
    }
}

// TODO
a.button, 
.button {
    &.button-white{background: #fff;color: $color-olive;}
    &.button-white-red{background: #fff;color: $color-milano-red;
        &:hover{background: $color-milano-red; color: #FFF;}
    }
    &.button-hide{ display: none;}
    &.pager-button {
        font-family: $fontfamilyheaders;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
        color: $color-grey;
        border-radius: 2px;
        background-color: #fff;
        border: 1px solid #fff;
        padding: 11px 15px;
        height: 44px;
        width: 44px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-right: 5px;
        &:last-child {
            margin-right: 0;
        }
        &.cur_page {
            
            background: $color-solitude;
            border: 1px solid $color-grey;
        }
        &:hover {
            border: 1px solid $color-grey;
        }
        &.prime-time {
            width: 131px;
            margin-right: 5px;
            cursor: pointer;
            @media (max-width: $mobile) {
                padding: 0;
                width: 98px;
                height: 32px;
                font-size: 14px;
                margin: 0 auto;
                margin-bottom: 10px;
            }
        }
    }
    &.button-reload {
        flex-shrink: 0;
    }
    &.button-mailbox {
        background-color: #465C71;
        color: #fff;
    }
    &.button-dark-blue {
        background-color: #004B60;
        color: #fff;
    }
    &#log_new_rand_photos{
        max-width: 175px;
        .fa{
            margin-left: 5px;
        }
    }
    &.load-more-mails,
    &#log_new_rand_photos,
    &.show_next_testimonial{
        .processed-loader{
            .dots{
                span{
                    background: $global-color-font;
                }
            }
        }
    }
    &.button-birthday {
        color: #fff;
        background-color: #e34789;
    }
    &.button-black {
        background-color: $global-color-font;
    }
    &.button-white-green {
        background-color: #fff;
        .slide-text {
            color: $color-olive;
        }
        
    }
}




a.button-transparent-black,
button.button-transparent-black {
    @extend .button;
    background-color: transparent;
    color: #000;
}
a.button-bordered-black,
button.button-bordered-black {
    @extend .button;
    padding: 14px 16px;
    border: 1px solid $color-link-water;
    border-radius: 2px;
    background: #fff;
    color: $global-color-font;
    &:active,
    &:hover,
    &.hovered,
    &.active,
    &.selected {
        border: 1px solid #465C71;
        background: #465C71;
        color: #fff;
        &:before {
            background-color: transparent;
        }
    }
}
a.button-bordered-transparent,
button.button-bordered-transparent {
    @extend .button;
    padding: 14px 16px;
    border: 1px solid $global-color-font;
    border-radius: 2px;
    background: transparent;
    color: $global-color-font;
}

.animate-btn {
    // height: 44px;
    &.button-small {
        // height: 32px;
    }
    .processed-loader {
        position: absolute;
        left: 0;
        width: 100%;
        transform: translateY(44px);
        transition: all .4s;
        text-align: center;
        .dots {
            transition: all .4s;
            span {
                display: inline-block;
                width: 8px;
                height: 8px;
                margin: 0 7px 0 0;
                border-radius: 50%;
                background-color: #fff;
                animation: wave;
                animation-duration: 1s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                &:nth-child(1) {
                    animation-delay: 0;
                }
                &:nth-child(2) {
                    animation-delay: .03s;
                }
                &:nth-child(3) {
                    margin-right: 0;
                    animation-delay: .06s;
                }
            }
        }
    }
    & > .container {
        position: relative;
        // hack for .container class, because of root body class .container
        width: unset;
        margin: unset;
        transition: all .4s;
        .slide-text {
            display: flex;
            align-items: center;
            justify-content: center;
            & > span {
                margin: 0 5px;
            }
            svg {
                transition: none;
            }
            .uadicon-chevron-down{
                font-size: 10px;
            }
        }
    }
    &[class*='icon-left'],
    &[class*='icon-right'] {
        & > .container {
            line-height: 44px;
        }
        .processed-loader {
            height: 10px;
            // transform: translateY(-26px);
            .dots {
                bottom: 0;
            }
        }
        &.processesd {
            .container {
                line-height: 44px;
            }
            .processed-loader {
                transform: translateY(-26px);
                .dots {
                    bottom: 0;
                }
            }
        }
    }
    &.processed {
        .container {
            top: -55px;
        }
        .processed-loader {
            transform: translateY(-2px);
            .dots {
                bottom: 0;
            }
        }
        &[class*='icon-left'],
        &[class*='icon-right'] {
            .container {
                top: -35px;
            }
            .processed-loader {
                // transform: translateY(-16px);
                .dots {
                    bottom: 0;
                }
            }
        }
    }
}
.button-reset {
    background: transparent;
    border: none;
    box-shadow: none;
}

.wrapper-button {
    position: relative;
    .badge {
        position: absolute;
        top: -8px;
        right: -8px;
        font-weight: 700;
    }
}

@keyframes button-hover-animation{
    0%{
        padding: 0;
        left: var(--left);
        top: var(--top);
    }
    100% {
        padding: 200% 200% 0 0;
        top:50%;
        left: 50%;
    }
}

@keyframes button-active-animation{
    0%   {
        padding: 0;
        left: var(--left);
        top: var(--top);
        opacity:1;
    }
    100% {
        padding: 200% 200% 0 0;
        top:50%;
        left: 50%;
        opacity: 0;
    }
}

a.button, 
button.button{
    &.hovered {
        &:before{
            position: absolute;
            content: "";
            background-color: rgba(0,0,0,.1);
            animation: button-hover-animation 350ms;
            border-radius:100%;
            transform: translate(-50%, -50%);top:50%;left: 50%;padding: 200% 200% 0 0;
        }
    }
}
a.button, 
button.button {
    &.active {
        &::after {
            position: absolute;
            content: "";
            background-color: rgba(0,0,0,.2);
            animation: button-active-animation 350ms;
            border-radius:100%;
            transform: translate(-50%, -50%);top:50%;left: 50%;padding: 200% 200% 0 0;
        }
    }
}
.button {
    &.button-happy-hour, &.button-info {
        svg {
			margin-right: 10px;
			font-size: 22px;
        }
    }
    &.button-happy-hour{
        max-width: 200px;
        color: #fff;
		background: linear-gradient(98.69deg, #6E36DF 0%, #9F3580 100%);
		
        @media (max-width: $mobile) {
            display: flex!important;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            margin-top: 10px;
        }
	}
    &.wink {
        &.disabled{
            background: #fff;
            border: 1px solid #C2C8CE;
            color: #C2C8CE;
            cursor: auto;
            svg{
                fill: #C2C8CE;
                font-size: 16px;
            }
            &:before{
                background: none;
            }
        }
    }

    &.wink-added {
        position: relative;
        overflow: hidden;
        cursor: default;

        &:hover {
            background: white;
        }

        @media(max-width: $mobile) {
            width: 130px;
            font-size: 12px;
            line-height: 18px;
            height: 42px;
            order: 3;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
        }
    }
}

.button-wrapper {
    position: relative;

    @media(max-width: $mobile) {
        order: 3;
        margin-right: 13px;
    }

    .label-primary {
        position: absolute;
        top: -10px;
        right: -5px;
        height: 20px;
        display: flex;
        align-items: center;

        @media(max-width: $mobile) {
            top: -7px;
            right: -4px;
        }
    }
}
