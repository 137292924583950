.news-feed-item  {
    .block-photo {
        &.width {
            @media (max-width: $mobile) {
                width: 280px;
            }
        }
        
        .favorites-girl {
            .block-girl-header {
                display: none;
            }
            .bottom-photo-block-fav {
                display: none;
            }
            @media (max-width: $mobile) {
                width: 280px;
            }
            .girl-photo-block-fav .girl-photo-wrap-container .chat-link .flex-chatnow-and-bday {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 20px;
                .icon-left-chat-now {
                    max-width: 186px;
                    width: 186px;
                    position: relative;
                    -webkit-transform: none;
                    transform: none;
                    margin-right: 10px;
                    left: auto;
                    bottom: auto;
                
                    .uadicon-chat-now {
                        font-size: 23px;
                    }
                }
                .button-bithday-fav {
                    max-width: 44px;
                    width: 44px;
                    position: relative;
                    -webkit-transform: none;
                    transform: none;
                    padding: 0;
                    left: auto;
                    bottom: auto;
                    background: #e34789;
                    color: #fff;
                    z-index: 99;
                    height: 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .mr-2 {
                        margin: 0!important;
                        font-size: 20px;
                    }
                }
            }
        }
    }
    
    .block-text {
        .block-girl-gift .girl-gift-photo {
            @media (max-width: $mobile) {
                width: 280px;
            }
            .favorites-girl {
                .block-girl-header {
                    display: none;
                }
                .bottom-photo-block-fav {
                    display: none;
                }
                .girl-photo-block-fav .girl-photo-wrap-container .chat-link .flex-chatnow-and-bday {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    bottom: 20px;
                    .icon-left-chat-now {
                        max-width: 186px;
                        width: 186px;
                        position: relative;
                        -webkit-transform: none;
                        transform: none;
                        margin-right: 10px;
                        left: auto;
                        bottom: auto;
                    
                        .uadicon-chat-now {
                            font-size: 23px;
                        }
                    }
                    .button-bithday-fav {
                        max-width: 44px;
                        width: 44px;
                        position: relative;
                        -webkit-transform: none;
                        transform: none;
                        padding: 0;
                        left: auto;
                        bottom: auto;
                        background: #e34789;
                        color: #fff;
                        z-index: 99;
                        height: 44px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .mr-2 {
                            margin: 0!important;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
        .curr_body_text {
            .news-title {
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 20px;
            }
            p {
                display: block;
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
            }
            .mt-3 {
                width: 100%;
            }
        }
        .button-play-video {
            display: none;
        }
        .media-object {
            margin: 0;
            .media-preview {
                height: 80px;
                &.audio .preview {
                    width: 40px !important;
                    left: 48%;
                    @media (max-width: $mobile) {
                        left: 22%;
                    }
                }
                &.video .preview {
                    @media (max-width: $mobile) {
                        width: 80px !important;
                    }
                }
            }
        }
    }
}

.lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
    transform: scale3d(0.95, 0.95, 1) !important;
}

.button {
   &.wink-added {
       opacity: .5;
   }
} 