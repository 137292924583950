input[type='radio']:not([class]) {
    display: none;
    & + label:not([class]) {
        display: inline-block;
        position: relative;
        min-height: 23px;
        padding-left: 31px;
        font-size: 14px;
        line-height: 23px;
        cursor: pointer;
        &:before,
        &:after {
            display: block;
            position: absolute;
            content: '';
        }
        &:before {
            left: 0;
            width: 20px;
            height: 20px;
            border: 1px solid $color-night-rider;
            border-radius: 50%;
        }
        &:after {
            display: none;
            top: 4px;
            left: 4px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: $color-night-rider;
        }
    }
    &:checked + label {
        &:after {
            display: inline-block;
        }
    }
    &:disabled + label {
        color: $color-link-water;
        cursor: not-allowed;
        &:before {
            background-color: $color-link-water;
        }
    }
}

.radiobutton-row {
    padding: 5px 20px;
    &.active {
        background-color: $color-solitude;
    }
    input[type=radio]:not([class])+label:not([class]) {
        &::before {
            top: 1px;
            border: 1px solid $color-grey;
        }
        &::after {
            top: 5px;
            background-color: $color-grey;
        }
    }
    &.center {
        input[type=radio]:not([class])+label:not([class]):before,
        input[type=radio]:not([class]):checked+label:not([class]):after {
            top: 50%;
            transform: translate(0, -50%);
        }
    }
}
