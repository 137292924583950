.block-pre-footer {
    position: relative;
    padding: 30px 0;
    background: $color-solitude;
	@media(max-width: $tablet) {
		padding: 30px 20px;
	}
	@media(max-width: $mobile) {
		padding: 30px 10px;
    }
    .block-to-top {
        height: 60px;
        text-align: center; 
        @media(max-width: $mobile) {
            margin-bottom: 20px;
        }
        .secondary-text {
            padding-top: 2px; 
            transition: padding .1s ease-out;
            color: $global-color-font;
            text-decoration: none;
            text-transform: uppercase;
            .uadicon-new-arrow-down {
                padding: 5px 0 0;
                transition: padding .15s ease-out;
                font-size: 28px;
                transform: rotate(180deg);
            }
            &:hover {
                // padding-top: 0px;
                // padding-bottom: 4px;
                & .uadicon-new-arrow-down {
                    padding: 0 0 5px;
                }
            }
        }
    }
}
footer {
    padding: 60px 0 0;
    background-color: #fff;
    font-family: $fontfamilyheaders;
    opacity: 1;
    @media(max-width: $tablet) {
        padding: 60px 0 0;
    }
    @media(max-width: $mobile) {
        padding: 30px 0 0;
    }
    .footer_top{
        padding: 0 80px;
        @media(max-width: $tablet) {
            padding: 0 20px;
        }
        @media(max-width: $mobile) {
            padding: 0 16px;
        }
    }
    .menu-footer {
        display: grid;
        padding-bottom: 60px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-column-gap: 20px;
        @media(max-width: 999px) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-row-gap: 60px;
        }
        @media(max-width: 799px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media(max-width: 599px) {
            grid-template-columns: 1fr 1fr;
        }
        @media(max-width: 460px) {
            grid-template-columns: 1fr;
        }
        .column {
            h3 {
                margin-top: 0;
                margin-bottom: 28px;
                font-weight: 500;
                @media(max-width: $mobile) {
                    margin-top: 0;
                    margin-bottom: 32px;
                }
            }
            .site-map {
                margin: 0;
                padding: 0;
                list-style: none;
                li {
                    padding-top: 10px;
                    &:first-child {
                        padding-top: 0;
                    }
                    a {
                        color: $global-color-font;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 18px;
                        text-decoration: none;
                        &:hover {
                            color: $color-navy;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
    .block-bottom-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid $color-grey;
        @media(max-width: 1400px) {
            flex-direction: column;
            align-items: center;
            flex-wrap: wrap;
            margin: 0;
        }
        @media(max-width: $mobile) {
            flex-direction: column;
            align-items: center;
            flex-wrap: wrap;
            margin: 0;
        }
        .policy-wrapper {
            width: 100%;
            padding: 20px 20px 20px 0;
            @media(max-width: 1400px) {
                width: auto;
                padding-bottom: 0;
            }
            @media(max-width: $mobile) {
                display: flex;
                align-items: baseline;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                padding: 0;
                padding-top: 20px;
            }
            div {
                @media(max-width: $mobile) {
                    margin-bottom: 20px;
                }
            }
            .mistake-info {
                font-family: $fontfamilyheaders;
                font-size: 14px;
                line-height: 21px;
                margin-right: 23px;
                @media(max-width: 767px) {
                   display: none!important;
                }
            }
            img {
                margin-right: 15px;
                &:last-child {
                    margin-right: 0;
                }
            }
            .dmca-badge {
                img {
                    width: 122px;
                }
            }
            .encrypt {
                width: 120px;
            }
            .visa {
                width: 66px;
                height: auto;
            }
            .gdpr {
                width: 84px;
            }
            .master {
                width: 52px;
            }
            
            a {
                margin-right: 20px;
                color: $global-color-font;
                font-family: $fontfamilyheaders;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                text-decoration: none;
                &:hover {
                    color: $color-navy;
                    text-decoration: underline;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
            
        }
        .wrapper-socials {
            display: flex;
            align-items: center;
            padding: 20px 0;
            @media(max-width: 767px) {
                padding:0 0 20px 0;
             }
            @media(max-width: $mobile) {
                justify-content: center;
                flex-wrap: wrap;
                max-width: 210px;
            }
            & > .item {
                display: flex;
                align-items: center;
                
                .social-label {
                    height: 28px;
                    margin-left: 10px;
                }
                .social-icon {
                    display: flex;
                    font-size: 24px;
                    line-height: 24px;

                    .fa {
                        font-size: 27px;
                        line-height: 24px;
                    }
                }
                &.fb {
                    margin-right: 20px;
                    .social-icon{
                        font-size: 26px;
                    }
                    
                }
                &.youtube {
                    margin-right: 20px;
                    img {
                        border-radius: 3px;
                        width: 24px;
                    }
                }
            }
        }
    }
}

.bottom--pd{
    .links-footer_wrap{
        @media(max-width: $mobile) {
            padding-bottom: 81px;
         } 
    }
}
.links-footer {
    margin-top: 20px;
    margin-bottom: 10px;
    @media(max-width: $mobile) {
        flex-wrap: wrap;
    }
    &_wrap{
        background: $color-solitude;
        padding: 20px 80px;
        @media(max-width: $tablet) {
            padding: 20px;
        }
        @media(max-width: $mobile) {
            padding: 20px 16px;
        }
    }
    a {
        margin-right: 10px;
        font-family: $fontfamilyheaders;
        font-size: 14px;
        line-height: 21px;
        text-decoration: none;
        color: #000;
        &:last-child {
            margin-right: 0;
        }
    }
}
.copyright-footer {
    width: 100%;
    // padding: 20px 0;
    text-align: center;
    p {
        font-family: $fontfamilyheaders;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 10px;
    }
}

.cookies-mess {
    position: fixed;
    bottom: 30px;
    left: 30px; 
    padding: 20px;
    color: #fff;
    background: $color-night-rider;
    border-radius: 2px;
    width: 300px;
    z-index: 121;
    @media (max-width: $mobile) {
        bottom: 10px;
        left: auto;
        right: auto;
        margin: 0 10px;
        width: calc(100% - 20px);
    }
    .cross {
        &::after, 
        &::before {
            background: #fff;
        }
    }
    p {
        margin: 0;
        &.title {
            margin-top: 0;
            margin-bottom: 20px;
            font-family: $fontfamilyheaders;
            font-size: 24px;
            line-height: 30px;
        }
    }
    a {
        color: #fff;
    }
}

.simple-footer {
    .block-bottom-footer {
        border: none;
        .wrapper-socials {
            display: none;
        }
        .policy-wrapper {
            justify-content: center;
            .mistake-info {
                display: none;
            }
        }
    }
    .links-footer_wrap {
        background: #fff;
    }
}
.login-page {
	.block-pre-footer {
		@media(max-width: $mobile) {
			display: none;
		}
	}
}
