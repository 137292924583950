h1 {
    margin: 30px 20px;
    padding: 0;
    color: $global-color-font;
    font-family: $fontfamilyheaders;
    font-size: 3rem;
    font-weight: normal;
    line-height: 3.25rem;

    @media (max-width: $mobile) {
        margin: 20px 16px;
        font-size: 30px;
        font-weight: 500;
        line-height: 30px;
    }
}

h2 {
    margin: 30px 0px;
    padding: 0;
    font-family: $fontfamilyheaders;
    font-size: 2.25rem;
    font-weight: normal;
    line-height: 2.625rem;

    a {
        color: $color-milano-red;
        font-size: 1em;
    }

    @media(max-width: $mobile) {
        margin: 30px 0px 20px;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.375rem;
    }
}

h3 {
    margin: 30px 0px 10px;
    padding: 0;
    font-family: $fontfamilyheaders;
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 1.875rem;

    @media (max-width: $mobile) {
        font-family: $fontfamily;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    &.red {
        color: $color-milano-red;
    }

    &.white {
        color: #fff;
    }

    &.uppercase {
        text-transform: uppercase;
    }
}

h1+h2,
section>h2:first-child,
section>h3:first-child,
h2+h3,
h1+p,
h2+p,
h3+p {
    margin-top: 0;
}

p+h2 {
    margin-top: 60px;
}

p+h3 {
    margin-top: 6px;
}

img+p {
    margin-top: 30px;
}

ul {
    padding: 0;
    list-style: none;
    list-style-position: inside;
    &.list-dots {
        li {
            position: relative;
            margin-bottom: 8px;
            padding-left: 15px;
            
        }
        li:before {
            position: absolute;
            top: 8px;
            left: 0px;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: $global-color-font;
            content: "";
        }
        &.red {
            li:before {
                background-color: #993333;
            }
        }
    }
    
    &.list-done {
        li {
            position: relative;
            margin-bottom: 10px;
            padding-left: 20px;
        }
        li:before {
            position: absolute;
            top: 0px;
            left: 0px;
            content: url('/inc/images/trips/new-list.svg');
        }
    }

    &.list-wrong {
        li {
            position: relative;
            margin-bottom: 10px;
            padding-left: 20px;
        }
        li:before {
            position: absolute;
            top: 0px;
            left: 0px;
            content: url('/inc/images/trips/wrong-list.svg');
        }
    }
    &.categories-menu {
        width: 100%;
        margin-bottom: 20px;

        li {
            width: 100%;
            // padding-bottom: 12px;
            a {
                text-decoration: none;
                color: $color-grey;
            }
            &.level-0 {
                a {
                    color: #000;
                }
            }
            &.see-also {
                a {
                    text-decoration: underline;
                    color: #000;
                }
            }
        }
    }
}

p {
    margin: 0;
    padding: 0;
    &+.videoplayerwrap {
        margin-top: 20px;
    }
    &.head,
    &.red {
        margin-bottom: .5em;
        font-size: 1.5em;
        font-weight: bold;
    }
    &.red {
        color: $color-milano-red;
    }
    &:empty {
        display: none;
    }
}

a {
    color: $color-navy;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
    &:visited {
        color: $color-navy;
    }
    &.dashed:hover {
        border-bottom: 1px dashed;
        text-decoration: none;
    }
    &.underlined { // это стандартные ссылки, дубль стилей в корне тут же, закомменитить
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}
a, p, span {
    &.gray {
        color: $color-grey;
    }
}
// Добавлено в старый global.scss коммитом 3791c5c20657
span.green {
    color: $color-olive;
}

.name-girl-mailbox {
    //new style
    font-family: $fontfamily;
    //new style end
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;	
}
.loader {
	text-align:center;
}
.title-wrap{
    padding: 10px 0 20px;
    border-bottom: 1px solid #C2C8CE;
    @media(max-width: $mobile){
        justify-content: center;
        padding: 15px 0 0;
        border-bottom: none;
    }
    h1{
        margin: 0;
    }
    &__total-items {
        font-size: 24px;
        line-height: 34px;
        @media(max-width:$tablet){
            display: none;
        }
    }
    &.visible-mob {
        display: none;
        @media(max-width:$tablet){
            display:block;
            font-size: 18px;
            line-height: 22px;
            font-weight: bold
        }
    }
}
.page-title{
    font-size: 24px;
    line-height: 30px;
    font-family: $fontfamilyheaders;   
    margin-bottom: 16px;   
    @media(max-width: $mobile) {
       text-align: center;
    }
}
.color--black{
    color: $global-color-font;
}
.color--yellow{
    color: #FFB802;
}
.color--grey{
    color: $color-night-rider;
}
.color--red{
    color: $color-milano-red;
}
.color--l-red{
    color: $color-red;
}
.color--l-grey{
    color: $color-grey;
}

