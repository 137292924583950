.label {
    padding: 0 4px;
    border-radius: 2px;
    font-family: $fontfamily;
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
    text-transform: uppercase;
    white-space: nowrap;
}

@each $key, $value in $theme-colors {
    .label-#{$key} {
        // @extend .label;
        background-color: $value;

        @if $key == 'warning' or $key == 'light' {
            color: $global-color-font;
        } @else {
            color: #fff;
        }
    }
}

.label {
    &.label-patriotic {
        background-color: #1f4c72;
    }
    &.label-halloween {
        background-color: #ca7c28;
        color: $global-color-font;
    }
    &.label-black-friday {
        background-color: $global-color-font;
        color: #fff;
    }
    &.label-best-offer {
        font-size: 12px;
        background-color: $color-red;
        color: #fff;
    }
    &.new {
        background-color: #b90404;
        color: #fff;
    }
    &.label-invited {
        background-color: #e34789;
        color: #fff;
    }
    &.label-soon {
        background-color: #FFB802;
        color: #000;
    }
    &.label-happy-hour {
        background: linear-gradient(98.69deg, #6e36df 0%, #9f3580 100%);
        color: #fff;
    }
    &.label-new-chat {
        background-color: #2D9CDB;
        color: #fff;
        top: 32px !important;
    }
    
    &.label-unlim {
        background-color: #00b05a;
        color: #fff;
    }
    &:empty {
        display: none;
    }
}

.alert {
    color: $color-milano-red;
}

.alerts {
    margin-bottom: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    &~.alerts {
        margin-bottom: 15px;
    }
    & p:last-child {
        padding-bottom: 0;
    }
    &.gray {
        background-color: $color-solitude;
        &.free-letter {
            margin: 20px;
            padding: 16px;
        }
    }
    &.yellow {
        background-color: #ffc863;
    }
    &.green{
        background: rgba(51, 153, 153, 0.12);
    }
    &.red{
        background: #FFE0E0;
    }
    &__top-page{
        position: relative;
        padding: 20px 11px;
        margin-bottom: 0;
        @media (max-width: $mobile){
            padding: 20px 16px;
        }
        * + .alerts__info {
            width: calc(100% - 121px);
            @media (max-width:$tablet) {
                width: calc(100% - 100px);
           }
        }
        .button{
            min-width: 115px;
            order: 1;
            & + .alerts__info {
               order: 0;
               padding-right: 27px;
               @media (max-width:$tablet) {
                    width: 100%;
                    padding: 0 0 8px;
               }
            }
        }
    }
    &__in-page{
        padding: 20px 10px;
        margin: 30px 0;
        @media (max-width:$mobile) {
            padding: 6px;
        }
        .alerts__icon {
            // font-size: 88px;
            // line-height: 88px;
            width: 88px;
            height: 88px;
            svg {
                font-size: 88px;
                fill: #2C3E50;
                color: #2C3E50;
            }
        }
        .alerts__info{
            width: calc(100% - 115px);
            @media (max-width:$mobile) {
                width: 100%;
            }
        }
    }
    &__title{
        font-family: $fontfamilyheaders;
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        padding-bottom: 8px;
        @media (max-width: $mobile){
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            padding-bottom: 20px;
        }
    }
    &__descr{
        font-size: 16px;
        line-height: 24px;
        p{
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    &__icon{
        position: relative;
        margin-right: 16px;
        .timer-second {
            width: 66px;
            height: 66px;
            border-radius: 50%;
            border: 2.75px solid #2C3E50;
            font-size: 36px;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .finish-icon {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            line-height: 66px;
            font-size: 66px;
        }
    }
  
}
.remove-alert{
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 12px;
    cursor: pointer;
    padding: 0;
}