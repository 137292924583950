input[type='number']:not([class]),
input[type='text']:not([class]),
input[type='email']:not([class]),
input[type='phone']:not([class]),
input[type='password']:not([class]),
input[type='date']:not([class]),

textarea,
select {
    display: block;
    width: 100%;
    padding: 6px 30px 5px 10px;
    transition: all .3s;
    border: 1px solid $color-grey;
    border-radius: 2px;
    background-color: #fdfdfd;
    color: $color-night-rider;
    font-family: $fontfamily;
    font-size: 1rem;
    filter: none;
    &:not(textarea):not(.prettytextfield):not(select) {
        height: 44px;
        line-height: 44px;
    }
    &:focus,
    &:focus-within {
        outline: none;
        background-color: #fff;
        & + label {
            top: -7px;
            background-color: #fdfdfd;
            color: $global-color-font;
            font-size: 12px;
        }
    }
    &:disabled {
        background-color: $color-white-smoke;
        cursor: not-allowed;
    }
    & + label {
        position: absolute;
        top: 15px;
        left: 10px;
        transition: all .1s linear;
        color: $color-grey;
        font-family: $fontfamily;
        font-size: 1em;
        line-height: 1em;
        cursor: text;
        pointer-events: none;
        &:after {
            content: attr(data-placeholder);
        }
    }
}
select {
    height: 44px;
    padding: 6px 30px 5px 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 1em top 50%, 0 0;
    background-size: .65em auto, 100%;
    background-image: url('/inc/images/icons/arrow-down.png');
    background-repeat: no-repeat;
}

input::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
    background-color: #fdfdfd !important;
    -webkit-box-shadow: 0 0 0 50px white inset;
}

input:-internal-autofill-selected {
    background-color: #fdfdfd;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

input[type=number] {
	-moz-appearance: textfield;
}
input[type=number]:hover,
input[type=number]:focus {
	-moz-appearance: number-input;
}
