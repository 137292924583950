$audio-backgrounds:
    linear-gradient(180deg, #6284FF 0%, #FF0000 100%),
    linear-gradient(0deg, #52ACFF 0%, #FFE32C 100%),
    linear-gradient(135deg, #FFE53B 0%, #FF2525 100%),
    linear-gradient(196.7deg, #FAACA8 0%, #DDD6F3 83.85%),
    linear-gradient(26.57deg, #21D4FD 16.67%, #B721FF 100%),
    linear-gradient(180deg, #2AF598 0%, #08AEEA 100%),
    linear-gradient(153.43deg, #FEE140 0%, #FA709A 83.33%),
    linear-gradient(26.57deg, #8EC5FC 16.67%, #E0C3FC 100%),
    linear-gradient(225deg, #FF3CAC 0%, #784BA0 48.96%, #2B86C5 100%),
    linear-gradient(135deg, #F4D03F 0%, #16A085 100%);

// .media-object.audio {
//     @each $bg in $audio-backgrounds {
//         $index: index($audio-backgrounds, $bg);
//         &:nth-of-type(#{$index}n ) {
//             background: $bg;
//         }
//     }
// }

.media-object {
    position: relative;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    transition: box-shadow 0.2s;
    // width: 100%;
    // height: 100%;
    border-radius: 2px;
    text-align: center;
    // overflow: hidden;
    cursor: pointer;
    @media (max-width: $mobile) {
        overflow: initial;
    }
    // .lightgallery-item {
    //     width: 100%;
    //     height: 100%;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    // }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        &.error {
            background-color: $color-red;
        }
        & + .error-text {
            position: absolute;
            color: #fff;
            font-family: $fontfamilyheaders;
            font-weight: 500;
            font-size: 14px;
        }
    }
    
    .holder {
        display: none;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        &.show {
            display: block;
        }
        .dropdown {
            position: absolute;
            top: 12px;
            right: 12px;
            .dropdown-toggle {
                width: 24px;
                height: 24px;
                font-size: 26px;
                line-height: 26px;
                border-radius: 50%;
                background-color: $color-solitude;
                span {
                    position: absolute;
                    left: 3px;
                    top: -9px;
                    font-size: 28px;
                }
            }
        }
        @media (max-width: $mobile) {
            display: block;
            .dropdown-menu {
                min-width: auto;
            }
        }
    }
    &.audio {
        @each $bg in $audio-backgrounds {
            $index: index($audio-backgrounds, $bg);
            &:nth-of-type(#{$index}n ) {
                background: $bg;
            }
        }
        .preview {
            width: 50px;
            height: auto;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    &:hover,
    &.loading  {
        .holder {
            display: flex;
        }
        .preview:before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.3);
            content: '';
        }
    }
    &.loading img {
        opacity: 0; 
    }
    &.can-selected {
        .uadicon-check{
            position: absolute;
            border: 3px solid #FFFFFF;
            border-radius: 50%;
            top: 10px;
            font-size: 22px;
            left: 10px;
            z-index: 10;
            color: #fff;
            background-color: $color-olive;
            opacity: 0;
        }
    }
    &.selected {
        .uadicon-check{
            opacity: 1;
        }
    }
    &.empty {
        display: none;
    }
    &.video{
        overflow: hidden;
        &.buy-media{
            img {
                filter: blur(4px);
            }
        }
    }
    
    &.attached {
        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: black;
            opacity: .5;
        }
    }

    &.file-loading {
        background-color: $color-solitude;
        display: flex;
        align-items: center;
        -webkit-justify-content: center;
        .percent, 
        .block-cancel {
            position: absolute;
            font-size: 18px;
        }
        .percent {
            .loading.gray:before {
                background-color: #d9dce1;
                width: 120px;
                height: 30px;
            }
        }
        .block-cancel {
            display: none;
        }
        .loading-circle {
            circle {
              fill: rgba(0,0,0,0);
              stroke: #000;
              opacity: .1;
              stroke-width: 10;
              stroke-dasharray: 204px 204px;
              transition: all 1s;
            }
            circle:nth-child(2n) {
              fill: rgba(0,0,0,0);
              stroke: #465c71;
              stroke-width: 10;
              opacity: 1;
            }
            &.small {
                width: 80px; 
                height: 80px;
                circle {
                    stroke-width: 5px
                }
            }
        }
        .load-text,
        .cancel-text {
            position: absolute;
            bottom: 4px;
            left: 4px;
            font-family: $fontfamilyheaders;
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            padding: 5px;
        }
        .cancel-text {
            display: none;
        }
        &.cancel:hover {
            .percent, 
            .load-text {
                display: none;
            }
            .block-cancel,
            .cancel-text {
                display: block;
            }
        }
    }
    &.invalid {
        background-color: $color-red;
        p {
            color: #fff;
            font-family: $fontfamilyheaders;
            font-weight: 500;
            font-size: 14px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .media-duration {
        right: 8px;
    }

    @-webkit-keyframes scroll {
        0% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
        100% {
            -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0)
        }
    }
     
    @-moz-keyframes scroll {
        0% {
            -moz-transform: translate(0, 0);
            transform: translate(0, 0);
        }
        100% {
            -moz-transform: translate(-100%, 0);
            transform: translate(-100%, 0)
        }
    }
     
    @keyframes scroll {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(-100%, 0)
        }
    }

    .media-name {
        left: 8px;
        max-width: 93%;
        display: block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        z-index: 5;
        @media (max-width: $mobile) {
            max-width: 60%;
        }
    }

    .media-name span {
        display: inline-block;
        padding-left: 100%;
        -webkit-animation: scroll 5s infinite linear;
        -moz-animation: scroll 5s infinite linear;
        animation: scroll 5s infinite linear;
    }

    .media-duration,
    .media-name {
        position: absolute;
        bottom: -1px;
        font-family: $fontfamilyheaders;
        font-weight: 500;
        color: #FFF;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
    }
}
.media-object,
.media-preview {
    .button-play-video svg{
        font-size: 83px;
    }
    .button-play-video--md svg{
        font-size: 67px;
        @media (max-width:$mobile) {
            font-size: 52px;
        }
    }
    .button-play-video--xs svg{
        font-size: 40px;
    }
    // &.video {
    //     .button-play-video svg{
    //         font-size: 83px;
    //     }
        // &:after {
        //     content: '';
        //     position: absolute;
        //     top: 50%;
        //     left: 50%;
        //     transform: translate(-50%, -50%);
        //     font-size: 40px;
        //     color: #fff;
        //     background: url('/inc/images/mailbox/play.svg') no-repeat 50% 50%;
        //     width: 83px;
        //     height: 83px;
        //     background-size: contain;
        // }
    // }
}
.media-object_sale {
    position: absolute;
    top: 0;
    left: 0;
    border: 32px solid transparent;
    border-left: 32px solid $color-red;
    border-top: 32px solid $color-red;
    z-index: 1;
    span {
        position: absolute;
        top: -26px;
        left: -26px;
        font-family: $fontfamilyheaders;
        font-weight: 500;
        color: #fff;
    }
}
.grid-files {
    display: flex;
    flex-wrap: wrap;
    // width: calc(100% + 4px);
    // margin-left: -4px;
    width: 100%;
    @media (max-width: 1000px) {
        justify-content: space-around;
    }
    @media (max-width: $mobile) {
        display: grid;
        // grid-template-columns: 140px 140px;
        grid-template-columns: repeat(2, 1fr);
        // grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 8px;
        grid-row-gap: 8px;
        padding: 0 16px;
        margin: 0 auto;
        width: 100%;
    }
    .media-object,
    .drag-n-drop {
        height: 177px;
        flex: 0 0 177px;
        margin: 4px;
        @media (max-width: $mobile) {
            // width: 42.47826vw;
            // height: 42.47826vw;
            width: calc(50vw - 20px);
            margin: 0;
            height: calc(50vw - 20px);
        }
        &.full {
            flex: auto;
        }
    }
}
.read-letter,
.files-from-lady {
    .media-object {
        @media(min-width: $mobile) {
            width: 240px;
            height: 240px;
        }
    }
    @media(max-width: $mobile) {
        justify-content: space-around;
        .title {
            max-width: 100px;
        }
    }
}
.media-with-properties {
    position: relative;
    width: 240px;
    margin: 0 4px 40px;
    @media (max-width: $mobile) {
        width: 44.47826vw;
        margin: 0 0 12px;
        .holder {
            display: block;
        }
    }
    .buy-media{
        position: relative;
        .button-play-video {
            display: none;
        }
        .media-preview:after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.3);
        }
    }
    
    .media-object_wrap{
        position: relative;
        .button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            border: 2px solid white;
            color: #fff;
            white-space: nowrap;
            z-index: 1;
            @media (max-width:$mobile) {
                font-size: 14px;
                height: 32px;
                padding: 5px 7px;
                top: 50%;
                left: 50%;
            }
        }
    }
    .properties {
        margin-top: 10px;
        text-align: left;
        .about-media {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .description {
                display: flex;
                flex-direction: column;
                width: calc(100% - 40px);
                .text {
                    white-space: nowrap;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 14px;
                }
                .date {
                    font-size: 12px;
                    color: $color-grey;
                }
            }
            a {
                margin-top: 3px;
                margin-right: 7px;
            }
            .uadicon {
                width: 19px;
                height: 19px;
                fill: #000;
            }
        }
    }
    &:hover {
        .holder {
            display: block;
        }
        // .properties {
            .button {
                background-color: $color-olive;
                border: none;
            }
        // }
    }
}

.delete-file {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    // background: $color-solitude;
    text-align: center;
    z-index: 1;
    .uadicon {
        font-size: 24px;
    }
}

/*style from media_global.scss*/
.media-preview {
	// float: left;
    display: inline-block;
	width: 100%;
	height: 100%;
	position: relative;
	cursor: pointer;
	overflow:hidden;
    &.declined {
        .preview {
            width: 100%;
        }
    }
	&.video img{
		width: 100%;	
	}
	// img {
	// 	float: left;
	// }
	&.needaccept.video img,
	&.needaccept.audio img {
		-webkit-filter: blur(1px);
		-moz-filter: blur(1px);
		-o-filter: blur(1px);
		-ms-filter: blur(1px);
		filter: blur(1px);
	}
	.media-duration {
		position: absolute;
		z-index: 1;
		right: 0;
		bottom: 0;
		font-size: 14px;
		color: #fff;
		padding: 40px 7px 2px;
		font-family: $fontfamilyheaders;
		background: linear-gradient(to top, $global-color-font -30%, transparent);
		width: 100%;
        text-align: right;
        @media(max-width:$tablet){
            bottom: 0;
        }
		&:empty {
			display: none;
		}
    }
}


.not-found-box,
.mails-not-found-box{
    display: block;
    padding: 50px 20px;
    color: $color-grey;
    line-height: 2em;
    text-align: center;
    h3 {
        color: #000;
    }
    .uadicon {
        font-size: 225px;
        color: $color-solitude;
        display: inline-block;
        padding-bottom: 40px;
    }
    strong {
        color: $global-color-font;
    }
}
