body,
html {
    &.show-blur-mask {
        .site-main-content.blur {
            &:before {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, .35);
                content: '';
                z-index: 9;

                @media screen and (max-width: 999px) {
                    display: none;
                }
            }
        }
        section.header {
            position: relative;
            z-index: 10;
        }
    }
    .menu-opened {
        @media(max-width: $tablet) {
            height: 0;
            margin: 0;
            overflow: hidden;
        }
    }
}

// DROP
.menu-drop-container {
    position: absolute;
    top: 45px;
    width: 1000px;
    @media (max-width: 1025px) {
        display: none;
    }
    .link-description,
    a .link-description {
        color: $color-navajo-white;
        font-family: $fontfamily;
        font-size: 13px;
        line-height: 18px;
        text-decoration: none;
    }
    .menu-text-link, 
    a .title,
    .title {
        display: flex;
        flex-direction: column;
        color: #fff;
        font-family: $fontfamilyheaders;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        &:hover {
            text-decoration: none;
        }
    }
    .menu-text-link {
        @media (max-width: 1025px) {
            flex-direction: row;
        }
    }
    .ladies-presets {
        font-size: 16px;
        font-family: $fontfamily;
        color: #fff;
    }
    a:visited{
        color:#fff;
    }
}

.menu-drop-wrapper {
    width: 1000px;
    max-height: 420px;
    border-radius: 0 0 5px 5px;
    background: $color-milano-red;
    font-family: $fontfamilyheaders;
    box-shadow:  0px 4px 16px rgba(51, 51, 51, 0.15);
    overflow: hidden;
    @media (max-width: 1025px) {
        display: none;
    }
    

    & span {
        display: inline-block;
        padding-top: 4px;
        line-height: 18px;
    }
    .menudarker {
        background: #7a2929;
    }
    .block-link-description {
        text-decoration: none;
        .title {
            text-decoration: underline;
        }
        &:hover {
            .title {
                text-decoration: none;
            }
        }
    }

    .block-link-description.small {
        margin-bottom: 15px;
        text-decoration: none;
        &:hover {
            .title-link {
                text-decoration: underline;
            }
        }
        .title-link {
            color: #fff;
            font-family: $fontfamily;
            font-size: 13px;
            line-height: 18px;
        }
        span {
            margin-top: 2px;
            padding-top: 0;
            color: $color-navajo-white;
            font-family: $fontfamily;
            font-size: 13px;
            line-height: 18px;
        }
    }
    // SHOW START
    &.show-user-profile {
        .menu-drop-profile {
            position: relative;
            top: 0px;
        }
    }
    &.show-menu-ladies {
        .menu-drop-ladies {
            position: relative;
            top: 0px;
        }
    }
    &.show-menu-video {
        .menu-drop-video {
            display: block;
            position: relative;
            top: 0;
            left: 0;
        }
        .menu-drop-videounl {
            position: relative;
            top: 0px;
        }
    }
    &.show-menu-trips {
        .menu-drop-trips {
            position: relative;
            top: 0px;
        }
    }
    &.show-menu-services {
        .menu-drop-services {
            position: relative;
            top: 0px;
        }
    }
    &.show-menu-agency {
        .menu-drop-agency {
            position: relative;
            top: 0px;
        }
    }
    &.show-menu-support {
        .menu-drop-support {
            position: relative;
            top: 0px;
        }
    }
    // SHOW END
    .menu-drop-item {
        display: flex;
        position: absolute;
        top: -3000px;
        justify-content: space-between;
        min-width: 1000px;
        min-height: 420px;
    }

    .owl-nav {
        display: flex;
        position: absolute;
        top: 40%;
        left: -40px;
        justify-content: space-between;
        width: 980px;
        margin-top: -20px;

        .disabled {
            svg {
                color: rgba(255, 255, 255, .3);
            }
        }

        svg {
            color: #fff;
            font-size: 24px;
            cursor: pointer;
        }
    }
}


.menu-drop-profile {
    .menu-profile-main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 30px;
        .menu-profile-name {
            a {
                display: inline-block;
                width: 220px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        .menu-profile-info {
            margin-top: 20px;
            text-decoration: none;
            &:hover {
                .edit {
                    text-decoration: underline;
                }
            }
            .edit-profile {
                font-size: 13px;
            }
            .edit-line {
                position: relative;
                width: 220px;
                height: 8px;
                margin-top: 5px;
                border-radius: 2px;
                background: $color-link-water;
                .edit-line-inner {
                    position: absolute;
                    height: 8px;
                    border-radius: 2px;
                    background: $color-navajo-white;
                }
            }
        }
        .menu-profile-credits {
            margin-top: 60px;
            .item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 5px;
                color: $color-navajo-white;
                font-size: 13px;
                text-decoration: none;
                cursor: pointer;
                &:hover {
                    .name {
                        text-decoration: underline;
                    }
                }
            }
            .credits-bottom {
                display: flex;
                margin-top: 5px;
                div:first-child {
                    margin-right: 20px;
                }
                a {
                    font-size: 13px;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .menu-profile-banner {
        width: 280px;
        background: url('/inc/images/menu/menu-profile-banner.png');
        a:not([href]) {
            cursor: default;
        }
        img {
            width: 100%;
        }
    }

    .menu-profile-message {
        padding: 29px 30px 30px;
        .item {
            color: #fff;
            font-size: 13px;
        }

        .count {
            color: $color-navajo-white;
            font-size: 13px;
            font-weight: bold;
            text-decoration: none !important;
        }

        .mails {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            text-decoration: none;
            &:hover .title {
                text-decoration: none;
            }
            .title {
                text-decoration: underline; 
            }
        }
        .chat {
            margin-top: 20px;
            .planned {
                margin-top: 5px;
            }
            .invitations {
                margin-top: 10px;                
            }
        }
        .sub-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 220px;
            margin-top: 5px;
            text-decoration: none;
            &:hover {
                .item {
                    text-decoration: underline;
                }
            }
        }
        .photo {
            margin-top: 20px;
            .files {
                margin-top: 8px;
            }
        }

        .support {
            margin-top: 31px;
            text-decoration: none;
            .title {
                text-decoration: underline;
                margin-bottom: 10px;
            }
            &:hover {
                .title {
                    text-decoration: none;
                }
            }
            .link-description {
                display: -webkit-box;
                width: 220px;
                text-overflow: ellipsis;
                overflow: hidden;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                white-space: pre-wrap;
            }
        }
    }

    .menu-profile-ladies {
        width: 160px;
        max-height: 419px;
        border-radius: 0 0 5px;

        .menu-profile-photo-small {
            .girl-photo-wrap {
                height: 240px;
            }

            .small-banner {
                position: relative;
                width: 160px;
                height: 240px;
                background: url('/inc/images/menu/menu-profile-banner-small.png');

                div {
                    display: flex;
                    position: absolute;
                    top: 0;
                    left: 0;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 160px;
                    height: 240px;
                    background: rgba(0, 0, 0, .65);
                    font-size: 16px;
                    text-align: center;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .my-ladies-block {
            padding: 13px 20px;
            .type {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 5px;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
                svg {
                    margin-top: 5px;
                }
                span {
                    display: block;
                    margin-top: 5px;
                    margin-left: 7px;
                    font-size: 13px;
                }
                &.favor {
                    div {
                        position: relative;
                        left: -15px;
                        .uadicon-favorites-filled {
                            position: absolute;
                            top: -11px;
                            left: 12px;
                            height: 16px;
                        }
                    }
                }

                &.other {
                    margin-left: -2px;
                    span {
                        margin-left: 8px;
                    }
                    svg {
                        color: #fff;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

.menu-profile-confirm {
    width: 220px;
    height: 132px;
    margin-top: 30px;
    border: 1px solid $color-navajo-white;
    font-weight: 400;
    text-decoration: none;
    &:hover {
        .profile-confirm-link {
            text-decoration: underline;
        }
    }
    .profile-confirm-top {
        display: flex;
        align-items: center;
        padding: 20px 10px 20px 16px;
        svg {
            width: 50px;
            height: 32px;
            color: #fff;
            &.mail {
                width: 65px;
            }
        }
        .text {
            margin-left: 14px;
            color: $color-navajo-white;
            font-size: 13px;
            line-height: 18px;
        }
    }
    .profile-confirm-link {
        font-size: 14px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;

    }

    @media(max-width: $tablet) {
        width: 100%;
        height: auto;
        padding: 20px 20px 20px 10px;
        .profile-confirm-top {
            padding: 0px;
        }
        .profile-confirm-link {
            margin-top: 15px;
            text-decoration: none;
        }
    }
}

.menu-drop-ladies {
    .menu-ladies-left {
        display: flex;
        position: relative;
        flex-direction: column;
        height: 100%;
        padding: 30px 0 10px 30px;

        .gallery-list {
            display: flex;
            flex-direction: column;

            a {
                display: inline-block;
                margin-top: 5px;
                font-size: 13px;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
                &.gallery-link {
                    font-size: 18px;
                    line-height: 24px;
                }
            }

            .with-circle {
                display: flex;
                align-items: center;
                margin-top: 5px;

                a {
                    margin-top: 0;
                }
                .circle {
                    width: 12px;
                    height: 12px;
                    margin-left: 10px;
                    border-radius: 50%;
                    background: $color-olive;
                }
            }

        }

        .menu-ladies-favorite {
            margin-top: 114px;
            color: #fff;

            .my-favorites {
                font-size: 13px;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
            svg {
                width: 16px;
                height: 16px;
                margin-top: 3px;
                margin-left: 5px;
            }
        }
    }

    &.with-search {
        flex-direction: column;
        .menu-ladies-left {
            .menu-input {
                display: flex;
                align-items: center;
                input {
                    width: 360px;
                    margin-bottom: 0px;
                }
                .icon {
                    top: 15px;
                    left: 330px;
                    width: 15px;
                    .girl-name-icon {
                        display: none;
                    }
                    .close {
                        display: block;
                    }
                }
                .menu-search-result {
                    margin-left: 20px;

                    span {
                        color: $color-navajo-white;
                        font-family: $fontfamily;
                        font-size: 16px;

                        a {
                            display: inline;
                            font-family: $fontfamily;
                            font-size: 16px;
                        }
                    }
                }

            }
        }
    }

    .menu-ladies-center {
        display: flex;
        justify-content: center;
        padding: 5px 0 0;
        &.loading {
            &::before {
                top: 40%;
                left: 47%;
            }
        }
        .menu-ladies-carousel {
            width: 490px;
            margin: 25px 0 25px -5px;
            overflow: hidden;
            &.carousel-created {
                opacity: 1 !important;
            }
            .owl-stage {
                display: flex;
            }
            // & > .girl-photo-wrap-container{
            //     display: none;
            // }
        }
    }

    .menu-ladies-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 268px;
        height: 420px;
        padding: 25px 30px 30px 18px;
        border-radius: 0 0 5px 0;
    }

    .menu-ladies-result {
        position: relative;
        height: 370px;
        margin: 0 45px;

        &.loading {
            visibility: hidden;
            animation: 1s fadeIn;
        }

        &.loading::before {
            top: 34%;
            left: 48%;
            visibility: initial;
        }

        .owl-item {
            margin-right: 10px !important;
        }
        
        .owl-stage-outer {
            overflow: hidden;
        }
        .owl-stage {
            display: flex;
            padding-left: 0 !important;
        }
        .owl-nav {
            left: -35px;
        }
    }
}

.menu-drop-video {
    display: none;
    left: -3000px;
    max-width: 1000px;
    overflow: hidden;

    .menu-video-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .menu-video-link {
            padding: 30px 46px 20px;
            color: $color-navajo-white;

            a {
                display: inline-block;
                margin-left: 5px;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .menu-video-credits {
            display: flex;
            align-items: center;
            margin-top: 15px;
            margin-right: 47px;

            .text {
                display: inline-block;
                margin-right: 20px;
                color: $color-navajo-white;
            }

            .button {
                width: 115px;
                padding: 4px 10px;
            }
        }
    }

    .menu-photos-container {
        position: relative;
        width: 91%;
        margin-top: 6px;
        margin-left: 40px;

        .owl-stage-outer {
            position: relative;
            height: 360px;
            padding: 0 4px;
            overflow: hidden;
            z-index: 2;

            .owl-stage {
                display: flex;
                padding-left: 0 !important;
            }

            .owl-item {
                min-width: 209px !important;
            }
            .chat-now-link-button {
                font-size: 16px;
                font-weight: 500;
                line-height: 14px;
            }
            .girl-photo-wrap {
                min-height: 313px;
                max-height: 313px;
            }
        }

        .owl-nav {
            display: flex;
            position: absolute;
            top: 0;
            left: -40px;
            justify-content: space-between;
            width: 1000px;
            margin-top: -20px;
            height: 100%;
            .owl-prev,
            .owl-next {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
            }
        }

    }

}

.menu-drop-videounl {
    .menu-videounl-left {
        .item {
            margin-top: 30px;
            margin-left: 30px;
            .link-description {
                width: 320px;
                margin-top: 6px;
            }
        }
    }
    .menu-videounl-right {
        .button-success {
            position: absolute;
            right: 225px;
            bottom: 30px;
            width: 190px;
            padding: 11px 0;
            font-size: 16px;
            text-align: center;
        }
    }
}

.menu-drop-trips {
    padding: 25px 30px 20px;

    .menu-trip-column {
        width: 320px;
        .block-link-description {
            margin-bottom: 32px;
        }
        &.right {
            .block-link-description {
                margin-bottom: 20px;
            }
        }
    }

    .menu-trip-img {
        .branches-images {
            position: relative;
            img {
                width: 240px;
                height: 360px;
            }
        }

        span {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 11px 16px;
            background: rgba(0, 0, 0, .7);
            color: #fff;
            font-size: 13px;
            font-weight: 400;
            text-align: center;
            z-index: 2;
        }
    }

    @media (max-width: $tablet) {
        padding: 0;
        .menu-trip-column {
            width: auto;
            .block-link-description {
                margin-bottom: 20px;
            }
            &.right {
                .title-link {
                    font-size: 16px;
                }
            }
        }
        .menu-trip-img {
            width: 80px;
            height: 1px;
            margin-bottom: 20px;
            border-bottom: 1px solid #fff;
        }
        
    }
}

.menu-drop-agency {
    a {
        padding-bottom: 34px;
        &:last-child {
            padding-bottom: 0;
        }
    }

    .menu-agency-left {
        min-width: 290px;
        max-width: 290px;
        padding: 26px 0px 30px 30px;
        border-radius: 0 0 0 5px;

        a {
            padding-bottom: 23px;
        }
    }

    .menu-agency-center {
        position: relative;
        margin: 23px 30px 30px;

        a {
            padding-bottom: 10px;
            padding-left: 19px;

            span {
                padding-top: 7px;
            }
        }

        .marriage-image {
            width: 308px;
            height: 177px;
            margin-top: 6px;
            background: url("/inc/images/menu/marriage_image.png") no-repeat right bottom;
            background-size: cover;

            div {
                position: relative;
                padding: 18px 0px;
                a {
                    &:hover {
                        text-decoration: none;
                    }
                    &:last-child {
                        margin-top: 5px;
                        font-size: 13px;
                    }
                }

                p {
                    width: 160px;
                    margin-top: 4px;
                    padding-bottom: 0px;
                    padding-left: 17px;
                    color: #fff;
                    font-family: $fontfamily;
                    font-size: 16px;
                    font-style: italic;
                    font-weight: normal;
                    line-height: 20px;
                }

                .read-more {
                    margin-top: 10px;
                }
            }
        }
    }

    .menu-agency-right {
        min-width: 350px;
        max-width: 350px;
        padding: 26px 30px 30px;
        padding-left: 0px;

        .testimonial-container {
            margin-top: 5px;
            text-decoration: none;
            &:hover {
                .title {
                    width: 100%;
                    text-decoration: none;
                }
            }
        }

        .testimonial {
            height: 100%;
            padding-bottom: 2px;
            text-decoration: none;
        }

        a {
            display: inline-block;
            padding-bottom: 0;
        }

        .link-description {
            max-height: 295px;
            padding-top: 4px;
            font-style: italic;
            overflow: hidden;
        }

        img {
            width: 120px;
            margin: 0 0 20px 20px;
            float: right;
        }

        .testsign {
            position: absolute;
            bottom: 45px;
            width: 350px;

            a {
                padding-bottom: 0;
                font-size: 13px;
                font-weight: normal;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }

            .link-description {
                margin-right: 30px;
                float: right;
            }
        }
    }

    @media (max-width: $tablet) {
        .menu-agency-left {
            padding: 0;
        }
        .menu-agency-center {
            margin: 0;
            a {
                padding: 0;
            }
        }
        .block-link-description {
            padding-bottom: 20px !important;
        }
        .menu-agency-right {
            min-width: auto;
            padding: 0;
            img {
                margin: 0 10px 10px 0;
                float: left;
            }
            .testsign {
                display: flex;
                position: initial;
                justify-content: space-between;
                width: auto;
                a {
                    padding-bottom: 0;
                    font-family: $fontfamily;
                    font-size: 13px;
                    line-height: 18px;
                    text-decoration: underline !important;
                }
            }
        }
    }
}

.menu-drop-support {
    .menu-support-img {
        position: relative;
        min-width: 330px;
        height: 420px;
        background: url("/../../inc/images/menu/support.png") no-repeat right bottom;
    }

    .block-support-ticket {
        position: absolute;
        top: 150px;
        left: 110px;
        width: 200px;
        height: 194px;
        padding: 20px;
        border-radius: 5px;
        background: #fff;
        text-decoration: none;

        &:hover {
            .title {
                text-decoration: none;
            }
        }

        &::before {
            position: absolute;
            top: -30px;
            left: 65px;
            width: 43px;
            height: 35px;
            background: url('/inc/images/menu/support-ticket.png');
            content: '';
        }

        .title {
            display: block;
            margin-bottom: 16px;
            color: #000;
            text-decoration: underline;
        }

        .text {
            display: -webkit-box;
            font-family: $fontfamily;
            font-size: 13px;
            line-height: 18px;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            color: #000;
            white-space: pre-wrap;
            font-weight: normal;
        }
        
    }

    .menu-support-center {
        width: 330px;
        padding: 30px 10px 0;
        a {
            display: block;
        }
        .office {
            margin-top: 36px;
        }
        .submit-question-now {
            width: 300px;
            height: 44px;
            margin: 18px 0 40px;
            border-radius: 2px;
            background: $color-navajo-white;
            color: $color-milano-red;
            font-size: 16px;
            line-height: 18px;
            text-align: center;
            text-decoration: none;
        }
    }

    .menu-support-right {
        padding: 30px 30px 30px 20px;

        .menu-support-right_wrapper {

            a {
                text-decoration: underline;


                &:hover {
                    text-decoration: none;
                }
            }

            span {
                margin-top: 0;
            }

            .reg-link,
            .women-real,
            .how-join {
                display: block;
                margin-top: 15px;
                color: $color-navajo-white;
                font-size: 13px;
                line-height: 18px;
                text-decoration: none;

                span {
                    padding-top: 0;
                }

                &:hover {
                    .registaration {
                        text-decoration: none;
                    }
                }

                .registaration {
                    color: #fff;
                    text-decoration: underline;
                }

            }

            .reg-link {
                margin-top: 5px;
            }
        }

        .site-map {
            margin-top: 18px;
        }
    }
}

.menu-drop-services {
    padding: 30px;
    a {
        font-weight: normal;
    }
    span {
        padding-top: 0;
    }
    .menu-services-left {
        width: 350px;

        .menu-services-icons {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 16px;

            a {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 20%;
                font-size: 12px;
                line-height: 24px;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }

                span {
                    display: block;
                    margin-top: 8px;
                }

                .preview-all {
                    display: inline-block;
                    height: 30px;
                    color: $color-beige;
                    font-size: 30px;
                }
            }
        }

        .title-link {
            width: 100%;
        }
    }

    .menu-services-center {
        margin-top: 35px;
        .content {
            display: flex;
            justify-content: space-between;
            margin-top: 9px;
            font-size: 13px;
            div {
                display: flex;
                flex-direction: column;
            }
            
            a {
                font-family: $fontfamily;
                display: inline-block;
                font-size: 13px;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            span {
                display: inline-block;
                color: #fff;
            }

        }
    }

    .menu-services-gifts {
        margin-top: 32px;

        .content {
            display: flex;
            justify-content: space-between;
            margin-top: 5px;

            a {
                display: inline-block;
                margin-top: 7px;
                font-size: 13px;
                line-height: 18px;
                text-decoration: none;
                font-family: $fontfamily;

                &:hover {
                    text-decoration: underline;
                }
            }

            div {
                &.second {
                    margin-left: 20px;
                }

                &.third {
                    margin-left: 40px;
                }

                a:not:first-child {
                    margin-top: 5px;
                }
            }
        }
    }

    .menu-services-banner {
        position: relative;
        width: 560px;
        height: 360px;

        a:not([href]) {
            cursor: default;
        }

        img {
            width: 100%;
        }

        .gradient {
            position: absolute;
            right: 0;
            left: 0;
            width: 560px;
            height: 360px;
            background: linear-gradient(90deg, rgba(54, 21, 7, 0) 0, rgba(54, 21, 7, 0.7) 100%);
            z-index: 1;
        }

        .wrapper {
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;
            width: 100%;
            height: 100%;
            padding: 20px 15px;
            color: #fff;
            font-family: $fontfamilyheaders;
            font-weight: bold;

            .gift-title {
                color: #fff;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                text-align: center;
                text-decoration: underline;
                text-transform: capitalize;
                z-index: 2;
            }

            .gift-text {
                font-size: 48px;
                line-height: 59px;
                text-align: right;
                text-transform: capitalize;
                z-index: 2;
            }

            .gift-cost {
                display: flex;
                z-index: 2;
                .from {
                    align-self: center;
                    text-align: center;
                    text-transform: lowercase;
                }

                .count {
                    margin: 0 2px 0 9px;
                    font-size: 48px;
                    line-height: 48px;
                    text-align: center;
                    text-transform: capitalize;
                }

                .cent {
                    font-size: 24px;
                    line-height: 24px;
                    text-align: center;
                    text-transform: capitalize;
                }
            }
        }

        .default {
            display: none;
            background: url('/inc/images/banners/menu-banners/main-banner.jpg') no-repeat;
            background-size: cover;
        }

        &.show-default {
            .default {
                display: block;
                width: 100%;
                height: 100%;
            }
            .gradient {
                background: radial-gradient(50% 50% at 50% 50%, rgba(35, 33, 33, 0) 45.83%, rgba(0, 0, 0, .47) 100%);
            }
        }

        .Flowers {
            display: none;
            background: url('/inc/images/banners/menu-banners/flowers.jpg') no-repeat 50% 50%;
            background-size: cover;

        }

        &.show-Flowers {
            .Flowers {
                display: flex;
            }
        }

        .GiftSets {
            display: none;
            background: url('/inc/images/banners/menu-banners/gift-sets.jpg') no-repeat;
            background-size: cover;
        }

        &.show-GiftSets {
            .GiftSets {
                display: flex;
            }
        }

        .FluffyToys {
            display: none;
            background: url('/inc/images/banners/menu-banners/fluffy-toy.jpg') no-repeat 50% 50%;
            background-size: cover;
        }

        &.show-FluffyToys {
            .FluffyToys {
                display: flex;
            }
        }

        .Lingerie {
            display: none;
            background: url('/inc/images/banners/menu-banners/lingerie.jpg') no-repeat;
            background-size: cover;
        }

        &.show-Lingerie {
            .Lingerie {
                display: flex;
            }
        }

        .ForHerBeauty {
            display: none;
            background: url('/inc/images/banners/menu-banners/body-care.jpg') no-repeat;
            background-size: cover;
        }

        &.show-ForHerBeauty {
            .ForHerBeauty {
                display: flex;
            }
        }

        .Perfumes {
            display: none;
            background: url('/inc/images/banners/menu-banners/perfumens.jpg') no-repeat;
            background-size: cover;
        }

        &.show-Perfumes {
            .Perfumes {
                display: flex;
            }
        }

        .Adornments {
            display: none;
            background: url('/inc/images/banners/menu-banners/adornments.jpg') no-repeat;
            background-size: cover;
        }

        &.show-Adornments {
            .Adornments {
                display: flex;
            }
        }

        .GiftsForKids {
            display: none;
            background: url('/inc/images/banners/menu-banners/gift-for-kids.jpg') no-repeat;
            background-size: cover;
        }

        &.show-GiftsForKids {
            .GiftsForKids {
                display: flex;
            }
        }

        .SPA-FUN {
            display: none;
            background: url('/inc/images/banners/menu-banners/spa-fun.jpg') no-repeat;
            background-size: cover;
        }

        &.show-SPA-FUN {
            .SPA-FUN {
                display: flex;
            }
        }
    }
}

.menu-input {
    position: relative;

    input {
        width: 160px;
        margin-bottom: 20px;
        padding: 11px 35px 9px 16px;
        border: 1px solid $color-beige;
        border-radius: 2px;
        background: transparent;
        color: #fff;
        &::placeholder {
            color: #fff;
        }
    }

    .icon {
        position: absolute;
        top: 13px;
        right: 10px;
        .girl-name-icon {
            width: 16px;
            height: 16px;
            color: $color-beige;
        }
        .close {
            display: none;
            width: 12px;
            height: 12px;
            color: $color-beige;
            cursor: pointer;
        }
    }

    
}

.menu-mobile-wrapper {
    .menu-input {
        @media(max-width: $tablet) {
            margin-top: 20px;
            padding: 0px 16px;
            input {
                width: 100%;
                // height: 44px;
                margin-bottom: 10px;
            }
            .icon {
                right: 30px;
                &.close {
                    top: 15px;
                    right: 30px;
                }            
            }
        }
    }
}

.menu-ladies-searches {
    display: flex;
    flex-direction: column;
    width: 160px;
    margin-top: -12px;
    border: 1px solid $color-beige;
    border-radius: 2px;
    list-style: none;
    li {
        width: 100%;
        padding: 9px 16px;
        &:hover {
            background: $color-beige;
            a {
                color: $global-color-font;
            }
        }
        a {
            display: inline-block;
            width: 100%;
            background: transparent;
            text-decoration: none;
        }
    }
    @media(max-width: $tablet) {
        width: 100%;
        margin: 8px 16px;
        &:active {
            background: $color-beige;
            a {
                color: #000;
            }
        }
    }
}

// mini-cart
// li.active .mini-cart-container {
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
// }
// .mini-cart-container {
//     height: 100%;
//     min-height: 520px;
// }

// MOBILE
.mobile-menu-opened {
    display: flex;
    width: 100%;
    @media (max-width: 600px) {
        width: 100%;
    }
    .menu-tablet-close {
        width: calc(100% - 600px);
        cursor: pointer;
        @media (max-width: 600px) {
            display: none;
        }
    }
    .menu-mobile-wrapper {
        position: relative;
        left: 0;
        transition: all .2s;
    }
}

.menu-mobile-wrapper {
    position: absolute;
    left: -3000px;
    flex-direction: column;
    width: 600px;
    height: calc(100vh - 40px);
    max-height: calc(100vh - 40px);
    transition: all .2s;
    background: $color-milano-red;
    overflow: scroll;
    @media (max-width: 600px) {
        width: 100%;
    }

    a {
        text-decoration: none !important;
    }

    ul {
        flex-direction: column;
        margin: 0;
        padding: 0;

        a {
            display: inline-block;
            width: 100%;
        }
    }

    .loading {
        width: 100%;
        height: 100%;

        &::before {
            top: 30%;
            left: 45%;
        }
    }

    .sign-out {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 20px 0 140px;

        svg {
            width: 20px;
            height: 20px;
            font-size: 20px;
            fill: $color-beige;
        }

        span {
            padding-right: 10px;
            padding-left: 20px;
            color: #fff;
            font-family: $fontfamilyheaders; 
        }
    }

    .mobmenu-search-close {
        width: 100%;
        height: calc(100% - 450px);
        margin-top: 5px;
    }

    &.hide {
        display: flex !important;
        padding-top: 0;

        ul {
            position: fixed;
            top: 43px;
            left: 0;
            width: 44px;
            height: 100vh;
            background: $color-milano-red;
        }

        .active {
            background: #7a2929;
        }

        a {
            display: block;
            color: #fff;
            font-family: $fontfamilyheaders;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            &.small {
                font-size: 13px;
            }
        }

        .menu-mobile-show {
            position: relative;
            left: 44px;
            width: calc(100% - 44px);
            min-height: 100vh;
            padding: 20px 16px;
            background: #7a2929; 

            .menu-mobile-support {
                max-height: 100%;
                padding-bottom: 40px;
                overflow: scroll;

                a {
                    padding-bottom: 20px;
                    &.faq {
                        padding-bottom: 0;
                    }
                }

                .block-support-ticket {
                    .text {
                        margin-top: 10px;
                        color: $color-navajo-white;
                        font-family: $fontfamily;
                        font-size: 13px;
                        line-height: 16px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                    }
                }

                .submit-question-now {
                    width: 100%;
                    height: 44px;
                    margin-bottom: 20px;
                    border-radius: 2px;
                    background: $color-beige;
                    color: $color-milano-red;
                    font-family: $fontfamilyheaders;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;
                    text-align: center;
                }

                .menu-support-left_wrapper {
                    margin: 10px 0 20px;
                    color: $color-navajo-white;
                    font-size: 13px;

                    &:first-child {
                        margin-bottom: 0;
                    }

                    b,
                    span {
                        display: inline-block;
                    }

                    b {
                        margin-top: 12px;
                        line-height: 17px;

                    }

                    span {
                        padding-top: 0;

                        a {
                            font-family: $fontfamily;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 13px;
                            line-height: 18px;
                            display: inline;

                        }
                    }

                    .reg-link,
                    .women-real,
                    .how-join {
                        padding-bottom: 0;
                        color: $color-navajo-white;
                        font-family: $fontfamily;
                        font-size: 13px;
                        line-height: 18px;
                    }

                    .reg-link {
                        b {
                            margin-top: 0;
                        }

                        .registaration {
                            color: #fff;
                            text-decoration: underline;
                        }
                    }
                }
            }

            .menu-mobile-profile {
                width: 100%;
                max-height: 100%;
                padding-bottom: 40px;
                color: #fff;
                overflow-y: scroll;

                a .small {
                    font-size: 13px;
                }

                .menu-profile-name {
                    a {
                        display: inline-block;
                        width: calc(100vw - 90px);
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                }

                .id {
                    color: $color-navajo-white;
                    font-family: $fontfamily;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 18px;
                }

                .edit-line {
                    display: block;
                    width: 100%;
                    height: 24px;
                    background: $color-link-water;
                    border-radius: 2px;
                    position: relative;
                    margin: 20px 0;

                    .edit-line-inner {
                        position: absolute;
                        height: 24px;
                        background: $color-navajo-white;
                    }

                    .percente {
                        position: absolute;
                        left: 47%;
                        font-family: $fontfamilyheaders;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 16px;
                        color: $color-milano-red;
                        top: 3px;
                    }
                }

                .title {
                    margin-top: 20px;
                }

                .count {
                    font-family: $fontfamilyheaders;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 13px;
                    line-height: 18px;
                    color: $color-navajo-white;
                }

                .mails,
                .planned,
                .invitations,
                .files {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                }

                .mails {
                    .count {
                        margin-top: 20px;
                    }
                }


                .planned,
                .invitations,
                .files {
                    font-family: $fontfamilyheaders;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 18px;
                    margin-top: 5px;
                }

                .support .text {
                    font-family: $fontfamily;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 18px;
                    color: $color-navajo-white;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    margin-top: 5px;
                }
            }
            .title-link {
                @media(max-width: $mobile) {
                    padding: 0;
                }
            }
        }

        .menu-mobile-search-icon {
            width: 22px;
            margin: 13px auto;
            padding: 12px 0;
            border-top: 1px solid $color-beige;
            border-bottom: 1px solid $color-beige;
        }
    }

    ul {
        flex-direction: column;

        li {
            display: flex;
            position: relative;
            align-items: center;
            width: 100%;
            padding: 12px 10px;
            font-weight: normal;
            a {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-left: 20px;
                color: #fff;
                text-decoration: none;
            }
            .label-happy-hour {
                display: inline-block;
                margin-left: 16px;
            }

            .icon {
                position: relative;
                height: 24px;
                .notify-support-mob {
                    display: block;
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    background: #FF0000;
                    top: 0;
                    right: -2px;
                }
            }
            svg,
            img {
                width: 24px;
                min-width: 24px;
                height: 24px;
                color: $color-beige;
                font-size: 24px;
            }

            .text {
                margin-left: 20px;
                color: #fff;
                font-family: $fontfamilyheaders;
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
                position: relative;
            }

            .triangle {
                display: block;
                position: absolute;
                right: 25px;
                width: 12px;
                height: 12px;
                border: 6px solid transparent;
                border-left: 6px solid $color-beige;
            }
        }
    }

    .menu-mobile-credits {
        margin-top: 24px;
        padding: 20px 0;
        border-top: 1px solid $color-beige;
        border-bottom: 1px solid $color-beige;

        .menu-credits-title {
            display: flex;
            align-items: center;
            width: 250px;
            padding-left: 10px;
            color: #fff;

            svg {
                color: $color-beige;
                font-size: 22px;
            }

            span {
                margin-left: 20px;
            }
        }

        .menu-credits-list {
            font-family: $fontfamilyheaders;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: $color-beige;
            padding-left: 54px;
            margin-top: 15px;
            width: 250px;


            li {
                padding: 5px 0 !important;

                span {
                    font-weight: bold;
                }
            }


        }
    }

    .menu-mobile-result {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        margin-left: -24px;
        padding: 0 16px;

        .count {
            margin-bottom: 15px;
            margin-left: 24px;
            color: $color-navajo-white;
            font-family: $fontfamily;
            font-size: 13px;
            line-height: 18px;

            a {
                color: #fff;
                font-family: $fontfamily;
            }
        }

        .ladies-container {
            display: flex;
            flex-wrap: wrap;

            .ladie-wrapper {
                position: relative;
                left: 16px;
                width: calc(50% - 16px);
                margin-bottom: 8px;
                margin-left: 8px;
                text-decoration: none;

                img {
                    width: 100%;
                }

                .name {
                    display: flex;
                    position: absolute;
                    bottom: 0;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 35px;
                    background: rgba(0, 0, 0, .7);
                    color: #fff;
                    font-family: $fontfamilyheaders;
                    text-align: center;
                }
            }
        }


    }

}

