.nav-tabs {
    display: flex;
    align-items: flex-end;
    margin: 0px 20px 0px;
    @media (max-width: $mobile) {
        margin: 0;
        background-color: $color-solitude;
        overflow-x: auto;
        &::-webkit-scrollbar { 
            width: 0px;
            height: 0;
            background: rgba(255, 255, 255, .0);
        }
    }
    .nav-item {
        border-bottom: 1px solid $color-link-water;
        display: inline-flex;
        position: relative;
        align-items: center;
        justify-content: center;
        padding: 20px 17px;
        color: $global-color-font;
        font-family: $fontfamilyheaders;
        font-size: 16px;
        line-height: 20px;
        text-decoration: none;
        text-transform: uppercase;
        white-space: nowrap;
        @media (max-width: $mobile) {
            padding: 15px 6px;
        }
        &.active,
        &:hover {
            color: $color-milano-red;
            &:after {
                display: block;
                position: absolute;
                right: 0;
                bottom: -1px;
                left: 0;
                content: '';
            }
        }
        &:hover {
            &:after {
                border-bottom: 3px solid $color-milano-red;
            }
        }
        &.active {
            color: $color-milano-red;
            font-weight: 700;
            &:after {
                border-bottom: 3px solid $color-milano-red;
            }
            .green {
                color: $color-milano-red;
            }
        }
        &.next {
            .uadicon {
                margin-left: 5px;
            }
        }
        & > span {
            margin: 0 5px;
        }
        .uadicon {
            margin-right: 5px;
        }
        .changer {
            font-size: 14px;
            color: #000;
        }
    }
    .content {
        width: 100%;
        position: relative;
    }
    &.lady-profile {
        border-bottom: 1px solid $color-link-water;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 30px;
        .nav-item {
            border: none;
        }
    }
    &.expand-items {
        .nav-item {
            flex-grow: 1;
        }
    }
    &.color-green .nav-item {
        &.active,
        &:hover {
            color: $color-olive;
        }
        &:hover {
            &:after {
                border-color: $color-olive;
            }
        }
        &.active {
            color: $color-olive;
            &:after {
                border-color: $color-olive;
            }
        }
    }
}
.tab-content {
    padding: 0 20px;
}

