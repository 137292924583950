@import '../variables';
input[type='checkbox']:not([class]) {
    display: none;
    & + label {
        display: inline-block;
        position: relative;
        min-height: 23px;
        padding-left: 31px;
        font-size: 14px;
        line-height: 23px;
        font-family: $fontfamily;
        cursor: pointer;
        &:before,
        &:after {
            display: block;
            position: absolute;
            content: '';
        }
        &:before {
            left: 0;
            width: 21px;
            height: 21px;
            border: 1px solid $color-night-rider;
            border-radius: 2px;
        }
        &:after {
            display: none;
            top: -1px;
            left: 0;
            background: url(/inc/images/icons/Check.svg) 0 1px no-repeat;
            width: 21px;
            height: 21px;
            background-size: contain;
        }
    }
    &:checked + label {
        &:after {
            display: inline-block;
        }
    }
    &:disabled + label {
        color: $color-link-water;
        cursor: not-allowed;
        &:before {
            background-color: #fff;
            opacity: .3;
        }
        &:after {
            opacity: .3;
        }
    }
}

.checkbox-row {
    &.error {
        .error-label {
            display: block;
        }
    }
    .error-label {
        display: none;
        margin-top: 5px;
        padding: 8px;
        background-color: $color-red;
        color: #fff;
        font-size: 12px;
    }
    &.checkbox-right {
        input[type='checkbox']:not([class]) + label {
            padding-left: 0;
            text-align: left;
            &::before {
                right: 0;
                left: auto;
            }
            &::after {
                right: 0px;
                left: auto;
            }
        }
    }
}
