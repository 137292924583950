// ЦВЕТОВАЯ ПАЛИТРА
// Оттенки размещены от тёмного к светлому, сверху вниз 


// Чёрно-белые оттенки
$color-night-rider: #333; // fonts
$color-grey: #858585; // icons, secondary stuff, buttons, input-borders, ... etc
$color-link-water: #c2c8ce;  // inactive borders
$color-white-smoke: #ebebeb; // section backgrounds
$color-solitude: #f1f3f5; // block backgrounds; TODO: rename
// $color-coconut-white: #fdfdfd; // unfocused textareas only 


// Красные оттенки
// $color-lasty: #7a2929;
$color-red: #f00;
$color-milano-red: #933;
$color-orange: #F56F53;


// Жёлтые оттенки
// $color-neon-carrot: #ff9e35;
$color-selective-yellow: #fbb802;
$color-navajo-white: #ffd6ab;
$color-beige: #f4f0d7;


// Синие оттенки
$color-navy: #000c7e;
$color-summer-sky: #2d9cdb;

// Зелёные оттенки
$color-olive: #45B61D;

// ТЕХНИЧЕСКАЯ ПАЛИТРА
// палитра для служебных компонентов и сообщений
$theme-colors: (
    primary: $color-milano-red,
    success: $color-olive,
    info: $color-summer-sky,
    warning: $color-selective-yellow,
    danger: $color-red,
    dark: $color-grey,
    light: $color-solitude,
    orange: $color-orange
);


// Размеры экранов
$tablet: 1024px;
$mobile: 767px;

// Fonts
$fontfamily: Open Sans;
$fontfamilyheaders: Montserrat;
$fontawesome: normal normal normal 14px/1 FontAwesome;
$global-color-font: #000;

// Создание пространства
$spacer: 1rem !default;
$spacers: (
    0: 0,
    1: $spacer * .25,
    2: $spacer * .5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
) !default;
