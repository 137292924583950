.input-row,
.textarea-row,
.checkbox-row,
.radiobutton-row,
.select-row {
    position: relative;
    .error-label {
        display: none;
        line-height: 1;
    }
    &.error {
        input[type="checkbox"] + label {
            &:before {
                border-color: $color-red;
            }
        }
        input[type="number"], 
        input[type="text"], 
        input[type="email"], 
        input[type="phone"], 
        input[type="password"], 
        input[type="date"], 
        textarea,
        select {
            border-color: $color-red;
        }
        .icon.error {
            display: inline-block;
            fill: #f00;
        }
        .error-label {
            display: block;
            margin-right: 0;
            transition: height .2s ease-out;
            z-index: 1;
            line-height: 1;
            &:after {
                padding: 0 11px;
                font-size: 12px;
                color: #FC0D1B;
                line-height: 15px;
                content: attr(data-error);
            }
            .uadicon{
                position: absolute;
                margin-right: 5px;
                vertical-align: middle;
                top: 16px;
                right: 10px;
                color: #FC0D1B;
            }
        }
        .show-pass{
            right: 35px;
        }
    }
    &.is-focused {
        input:not([class]),
        textarea:not([class]),
        select {
            outline: none;
            background-color: #fff;
            & + label {
                top: -7px;
                background-color: #fdfdfd;
                color: $global-color-font;
                font-size: 12px;
                // ::webkit-autofill hack
                opacity: 1;
            }
        }
    }
    // icons
    .icon {
        position: absolute;
        top: 10px;
        right: 10px;
        fill: $color-grey;
        svg {
            height: 100%;
        }
        &.calendar {
            width: 24px;
            height: 24px;
            // right: 0;
        }
        &[class*='airplane'] {
            width: 32px;
            height: 32px;
            top: 17px;
        }
        &.airplane-to {
            transform: rotate(45deg);
            right: 20px;
        }
        &.error {
            height: 16px;
            width: 16px;
            top: 14px;
            right: 14px;
        }
    }
    .input-datetimepicker {
        position: absolute !important;
        top: 100%;
        left: 0;
        margin-left: 0;
    }
    &.input-calendar {
        flex: 0 1 160px;
    }
    .show-pass{
        position: absolute;
        top: 14px;
        right: 15px;
        // font-size: 18px;
        cursor: pointer;
        width: 16px;
    }
}

.textarea-row {
    .block-symbols-counter,
    .block-counter {
        position: absolute;
        right: 10px;
        bottom: 7px;
        color: $color-grey;
        font-size: 12px;
    }
}
