// Flex: display options
.d-flex {
    display: flex;
    &.width {
        width: 100%;
    }
}

.flex-row {
    flex-direction: row;
}
.flex-column {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}
.flex-no-shrink {
    flex-shrink: 0;
}

// Flex: align content
.justify-content-start {
    justify-content: flex-start;
}
.justify-content-end {
    justify-content: flex-end;
}
.justify-content-center {
    justify-content: center;
}
.justify-content-between {
    justify-content: space-between;
}
.justify-content-around {
    justify-content: space-around;
}

// Flex: align items
.align-items-start {
    align-items: flex-start;
}
.align-items-end {
    align-items: flex-end;
}
.align-items-center {
    align-items: center;
}

@each $space, $value in $spacers {
    .m-#{$space} {
        margin: #{$value} !important;
    }
    .mt-#{$space} {
        margin-top: #{$value} !important;
    }
    .mr-#{$space} {
        margin-right: #{$value} !important;
    }
    .mb-#{$space} {
        margin-bottom: #{$value} !important;
    }
    .ml-#{$space} {
        margin-left: #{$value} !important;
    }
    .p-#{$space} {
        padding: #{$value} !important;
    }
    .pt-#{$space} {
        padding-top: #{$value} !important;
    }
    .pr-#{$space} {
        padding-right: #{$value} !important;
    }
    .pb-#{$space} {
        padding-bottom: #{$value} !important;
    }
    .pl-#{$space} {
        padding-left: #{$value} !important;
    }
}


// Fonts

.text-bold {
    font-weight: bold;
}
.text-italic {
    font-style: italic;
}
.text-center {
    text-align: center;
}
.text-uppercase {
    text-transform: uppercase;
}
.bold-and-italic {
    font-style: italic;
    font-weight: bolder;
}
.underline {
    text-decoration: underline;
}


.clearfix {
    &:after,
    &:before {
        display: table;
        clear: both;
        content: '';
    }
}

// Grid helpers
.padding-wrap{
    padding: 0 20px;
}
.padding-desktop, 
.padding-desctop { 
    padding: 0 20px; 
    @media(max-width: $tablet) {
        padding: 0;
    }
}
.padding-mobile{
    padding-right: 0px !important;
    padding-left: 0px !important;
    @media(max-width: $tablet) {
        padding-right: 20px !important;
        padding-left: 20px !important;
    }
    @media(max-width: $mobile){
        padding-right: 16px !important;
        padding-left: 16px !important; 
    }
}

@media(min-width: $mobile) {
    .hide-from-desktop {
        display: none!important;
    }
}
.mx-60{
    margin: 60px 0;
    @media(max-width: $mobile) {
        margin: 40px 0;
    }
}
.mt-60{
    margin-top: 60px;
    @media(max-width: $mobile) {
        margin-top: 40px;
    }
}
.mb-60{
    margin-bottom: 60px;
    @media(max-width: $mobile) {
        margin-bottom: 40px;
    }
}
.mt-80{
    margin-top: 80px;
    @media(max-width: $mobile) {
        margin-top: 40px;
    }
}
.mb-25 {
    margin-bottom: 25px;
}
.padding-static {
    padding: 20px;
    @media(max-width: $mobile){
        padding: 20px 16px;
    }
}
