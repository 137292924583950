.secondary-text {
	font-size: .87em;
	line-height: 1.45em;
}
.site-box-shadow {
	transition: all .3s cubic-bezier(.25, .8, .25, 1);
	box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.15);
}
main {
	opacity: 1;
}
footer {
	opacity: 1;
}
header {
	.header-menu-section {
		&:before {
			display: none;
		}
	}
}