.girl-photo-wrap-container {
	position: relative;
	text-align: center;
	z-index: 2;
	width: 100%;
    max-height: 420px;
    height: 100%;
    &:hover {
        .title {
            display: none;
			background-color: rgba(0, 0, 0, 0);
			// @media(min-width: $tablet) {
			// 	background-color: rgba(0, 0, 0, 0);
			// }
			@media(max-width: $mobile) {
				display: flex;
				background-color: rgba(0, 0, 0, 0.6);
			}
        }
		.girl-photo-wrap{
			// .shadow{
			// 	background-color: transparent;
			// }
			.shadow-all,
			.shadow-static {
				opacity: 1;
			}
			// .view-profile{
			// 	display: flex;
			// 	width: 100%;
			// 	height: 100%;
			// 	justify-content: center;
			// 	align-items: center;
			// 	cursor: pointer;
			// }
			// .branch {
			// 	display: block;
			// 	width: 100%;
			// 	height: 100%;
			// 	.view_text {
			// 		width: auto;
			// 		height: auto;
			// 		position: absolute;
			// 		top: auto;
			// 		bottom: 8px;
			// 		left: auto;
			// 		right: 4px;
			// 		transform: none;
			// 	}
			// }
			.chat-page-list-hover-button{
				opacity: 1;
				display:inline-block;
				-webkit-transition: all 0s;
				-moz-transition: all 0s;
				-ms-transition: all 0s;
				-o-transition: all 0s;
				transition: all 0s;
				.chat-now-link-button {
					transform: translate(-50%, 0);
				}
			}
			// img{
			// 	-moz-transform: scale(1.1);
			// 	-webkit-transform: scale(1.1);
			// 	-o-transform: scale(1.1);
			// 	-ms-transform: scale(1.1);
			// 	transform: scale(1.1);
			// }
		}
		// .view-profile-mob {
		// 	opacity: 1;
		// 	z-index: 2;
		// }
		// @media(min-width: $tablet) {
		// 	.girl-photo-wrap{
		// 		.shadow{
		// 			background-color: transparent;
		// 		}
		// 		.shadow-static,.shadow-all{
		// 			opacity: 1;
		// 		}
		// 		.view-profile,
		// 		.choose-lady{
		// 			display: flex;
		// 			width: 100%;
		// 			height: 100%;
		// 			justify-content: center;
		// 			align-items: center;
		// 			cursor: pointer;
		// 		}
		// 		// .branch {
		// 		// 	display: block;
		// 		// 	width: 100%;
		// 		// 	height: 100%;
		// 		// 	.view_text {
		// 		// 		width: auto;
		// 		// 		height: auto;
		// 		// 		position: absolute;
		// 		// 		top: auto;
		// 		// 		bottom: 8px;
		// 		// 		left: auto;
		// 		// 		right: 4px;
		// 		// 		transform: none;
		// 		// 	}
		// 		// }
		// 		.chat-page-list-hover-button{
		// 			display:inline-block;
		// 			-webkit-transition: all 0s;
		// 			-moz-transition: all 0s;
		// 			-ms-transition: all 0s;
		// 			-o-transition: all 0s;
		// 			transition: all 0s;
		// 		}
		// 		img{
		// 			-moz-transform: scale(1.1);
		// 			-webkit-transform: scale(1.1);
		// 			-o-transform: scale(1.1);
		// 			-ms-transform: scale(1.1);
		// 			transform: scale(1.1);
		// 		}
		// 	}
		// }
		// @media(max-width: $tablet) {
		// 	.girl-photo-wrap{
		// 		.shadow{
		// 			background-color: transparent;
		// 		}
		// 		.shadow-static, .shadow-all{
		// 			opacity: 1;
		// 		}
		// 		.view-profile,
		// 		.choose-lady{
		// 			display: flex;
		// 			width: 100%;
		// 			height: 100%;
		// 			justify-content: center;
		// 			align-items: center;
		// 			cursor: pointer;
		// 		}
		// 		.chat-page-list-hover-button{
		// 			display:inline-block;
		// 			-webkit-transition: all 0s;
		// 			-moz-transition: all 0s;
		// 			-ms-transition: all 0s;
		// 			-o-transition: all 0s;
		// 			transition: all 0s;
		// 		}
		// 	}
		// }
		// .girl-photo-wrap-no-hover{
		// 	.shadow-static, .shadow-all{
		// 		display: none;
		// 	}
		// 	.view-profile{
		// 		display: none;
		// 	}
		// 	&:hover{
		// 		.shadow-all,.shadow-all{
		// 			display: none;
		// 			background-color: rgba(51, 51, 51, 0.25);
		// 			border-radius: 0 0 0 50%;
		// 			width: 120px;
		// 			height: 120px;
		// 			position: absolute;
		// 			right: -60px;
		// 			top: -60px;
		// 			z-index: 1;
		// 		}
		// 		.view-profile{
		// 			display: none;
		// 		}
		// 	}
		// }
		// .girl-photo-wrap{
		// 	img{
		// 		-moz-transform: scale(1.1);
		// 		-webkit-transform: scale(1.1);
		// 		-o-transform: scale(1.1);
		// 		-ms-transform: scale(1.1);
		// 		transform: scale(1.1);
		// 	}
		// }
    }
	&.chat-video {
		.girl-photo-wrap {
			max-height: 355px;
			min-height: 355px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			@media(max-width: $mobile) {
				max-height: 420px;
			}
			video {
				width: 100%;
			}
		}
		.photo-small {
			width: 60px;
			height: 90px;
			position: absolute;
			bottom: -4px;
			left: -4px;
			z-index: 10;
			opacity: 1;
			transition: visibility 0.3s, opacity 0.3s ease-out;
			img {
				width: 100%;
				border-radius: 2px;
			}
		}
		.button-chat-video {
			width: calc(100% - 40px);
			transition: transform 0.3s ease-out;
			transform: translate(-50%, 100px);
			&.button-warning {
				color: #000;
				font-weight: 500;
				font-size: 16px;
				line-height: 24px;
			}
		}
		.uadicon-play-circle {
			position: absolute;
			width: 73px;
			height: 73px;
			opacity: 0.7;
			color: #fff;
		}
		&:hover {
			@media(min-width: $tablet) {
				.photo-small,
				.uadicon-play-circle {
					opacity: 0;
					visibility: hidden;
				}
				.button-chat-video {
					transform: translate(-50%, 0);
				}
			}
		}
		.video-wrapper {
			background-size: cover;
			&::before {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				backdrop-filter: blur(4px);
				top: 0;
				left: 0;
			}
			&.playing {
				.uadicon-play-circle,
				.photo-small {
					opacity: 0;
					visibility: hidden;
				}
			}
			.chat-video-container {
				width: 100%;
				height: 100%;
			}
		}
	}
    .title {
        display: flex;
        position: absolute;
        bottom: 0;
        left: 0;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40px!important;
        background-color: rgba(0, 0, 0, .6);
        color: #fff;
        font-family: $fontfamilyheaders;
		font-size: 16px!important;
		line-height: 20px!important;
		font-weight: 500!important;
        text-transform: capitalize;
		transition: all .3s;
		z-index: 2;
    }
	// .shadow {
		// display:flex;
		// align-items:flex-end;
		// justify-content:center;
		// float: left;
		// width: 100%;
		// font-size: 16px;
		// font-family: $fontfamilyheaders;
		// color: #fff;
		// text-align: center;
		// padding: 7px 0;
		// background: rgba(0,0,0,.5);
		// position: absolute;
		// left: 0;
		// bottom: 0;
		// font-weight: 500;
		// min-height: 38px;
	// 	-moz-transition: all 0.3s;
	// 	-o-transition: all 0.3s;
	// 	-webkit-transition: all 0.3s;
	// 	z-index:2;
	// 	text-transform: capitalize;
	// }
	&.show {
        height: 420px;
    }
    .label {
        position: absolute;
        top: 10px;
        left: -4px;
        z-index: 4;
    }
    .button,
    .block-buttons {
        position: absolute;
        bottom: 20px;
        left: 50%;
        max-width: 240px;
        transform: translateX(-50%);
        white-space: nowrap;
        z-index: 4;
		width: 100%;
    }
    // .block-buttons {
    //     display: flex;
    //     justify-content: space-between;
    //     .button {
    //         position: relative;
    //         bottom: auto;
    //         left: auto;
    //         transform: none;
    //     }
    // }
	// .button{
	// 	margin-top: 10px;
	// 	&.want-chat{
	// 		line-height:2em;
	// 		svg{
	// 			width:2em;
	// 			height:2em;
	// 			display:inline-block;
	// 			margin-right:10px;
	// 			margin-bottom:-0.6em;
	// 		}
	// 	}
	// }
    &.lady-blur {
        .girl-photo-wrap img {
            filter: blur(8px);
        }
	}
}

// .lady-preview {
//     display: flex;
//     align-items: center;
//     justify-content: flex-start;
//     padding: 68px 0 105px 80px;
// 	box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.15);
// 	position: absolute;
// 	top: 1px;
// 	left: -20px;
// 	background: #fff;
// 	z-index: 5;
// 	width: calc(100% + 40px);
// 	@media (max-width: $mobile) {
// 		flex-direction: column;
// 	}
//     img {
//         margin-right: 168px;
// 		@media (max-width: $mobile) {
// 			margin: 0 0 20px 0;
// 		}
//     }

//     .text {
//         .title {
//             padding-bottom: 20px;
//             color: $color-milano-red;
//             font-family: $fontfamilyheaders;
//             font-size: 48px;
//             font-style: normal;
//             font-weight: bold;
//             line-height: 36px;
//             text-transform: uppercase;
//         }
//         .name {
//             display: inline-block;
//             position: relative;
//             padding-right: 35px;
//             color: $color-milano-red;
//             font-size: 36px;
//             line-height: 42px;
//             sup {
//                 position: absolute;
//                 top: -6px;
//                 color: $global-color-font;
//                 font-size: 16px;
//                 line-height: 24px;
//             }
//         }
//     }
// }
.girl-photo-wrap {
	// float: none;
	width: 100%;
	border-radius: 2px;
	overflow: hidden;
	position: relative;
	// @media (max-width: $mobile){
	// 	.write-mail-girl-wrap & {
	// 		.ladies-photo{width:60px; border-radius:50%; height:60px;}

	// 	}
	// 	// float:none;
	// }

	// ???
	// &.selected{
	// 	transition: box-shadow .3s cubic-bezier(.25, .8, .25, 1);
	// 	box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
	// 	-moz-transition: all 0.3s;
	// 	-o-transition: all 0.3s;
	// 	-webkit-transition: all 0.3s;
	// 	.choose-lady{
	// 		span{
	// 			display: none;				
	// 		}
	// 	}
	// 	@media(min-width: $tablet) {
	// 		img{
	// 			-moz-transform: scale(1.1) translate(0,14px);
	// 			-webkit-transform: scale(1.1) translate(0,14px);
	// 			-o-transform: scale(1.1) translate(0,14px);
	// 			-ms-transform: scale(1.1) translate(0,14px);
	// 			transform: scale(1.1) translate(0,14px);
	// 		}
	// 		.shadow{
	// 			background-color: transparent;
	// 		}
	// 		.shadow-static, .shadow-all{
	// 			opacity: 1;
	// 		}
	// 		.view-profile,
	// 		.choose-lady{
	// 			display: inline-block;
	// 		}
	// 		.chat-page-list-hover-button{
	// 			display:inline-block;
	// 			-webkit-transition: all 0s;
	// 			-moz-transition: all 0s;
	// 			-ms-transition: all 0s;
	// 			-o-transition: all 0s;
	// 			transition: all 0s;
	// 		}
	// 		span.title{
	// 			background-color: rgba(0, 0, 0, 0);
	// 		}
	// 	}
	// }	
	img, 
	img.ladies-photo {
		// float: left;
		width: 100%;
		object-fit: cover;
		height: 100%;
		-moz-transition: all 0.3s;
		-o-transition: all 0.3s;
		-webkit-transition: all 0.3s;
	}

	// .choose-lady, 
	// .view-profile {
	// 	z-index: 3;
	// 	display:none;
	// 	white-space:nowrap;
	// 	font-family: $fontfamilyheaders;
	// 	color: #fff;
	// 	font-size: 16px;
	// 	font-weight: 400;
	// 	line-height: 24px;
	// 	text-align: center;
	// 	position: absolute;
	// 	top: 50%;
	// 	left: 50%;
	// 	-webkit-transform: translate(-50%,-50%);
	// 	-ms-transform: translate(-50%,-50%);
	// 	-o-transform: translate(-50%, -50%);
	// 	transform: translate(-50%,-50%);
	// 	-webkit-transition: all 0.3s;
	// 	-moz-transition: all 0.3s;
	// 	-ms-transition: all 0.3s;
	// 	-o-transition: all 0.3s;
	// 	transition: all 0.3s;
	// 	text-transform: uppercase;
	// 	text-decoration: none;
	// 	@media(max-width: $mobile){
	// 		// display: block;
	// 		width: 100%;
	// 		height: 100%;
	// 		div{
	// 			display: none;
	// 		}
	// 	}
	// }
	.shadow-all,
	.shadow-static {
		display:flex;
		align-items:flex-end;
		justify-content:center;
		float: left;
		width: 100%;
		font-size: 14px;
		font-family: $fontfamilyheaders;
		color: #fff;
		text-align: center;
		padding: 10px 0;
		background: rgba(0, 0, 0, 0.6);
		position: absolute;
		left: 0;bottom: 0;
		font-weight: 400;
		min-height: 40px;
		-moz-transition: all 0.3s;
		-o-transition: all 0.3s;
		-webkit-transition: all 0.3s;		
		z-index:1;
		opacity: 0;
		width: 100%;
		height: 100%;
			@media(max-width: $mobile){
				display: none;
			}
		}
	.bday{
		background-color: #FBB802;
		color: $global-color-font;
		.bday-icon{
			background: url('/inc/images/icons/b-day-icon.png') no-repeat center;
		}
	}
	// .view-profile-in-chat{
	// 	display: none;
	// 	text-align: center;
	// 	a{
	// 		color: #fff;
	// 		text-decoration: underline;
	// 	}
	// }
	.chat-page-list-hover-button{
		cursor: pointer;
		z-index: 3;
		opacity: 0;
		white-space:nowrap;
		font-family: $fontfamilyheaders;
		color: #fff;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		text-align: center;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-ms-transition: all 0.3s;
		-o-transition: all 0.3s;
		transition: all 0.3s;
		// &.show {
		// 	display: block;
		// 	.link-view-profile {
		// 		display: none;
		// 	}
		// 	&:hover {
		// 		.link-view-profile {
		// 			display: flex;
		// 		}
		// 	}
		// }
		.chat-now-link-button{
			margin-top: 0;
			margin-bottom: 0;
			position: absolute;
			bottom: 20px;
			left: 50%;
			transform: translate(-50%, 100px);
			transition: transform 0.3s ease-out;
			width: calc(100% - 40px);
			white-space: nowrap;
        	z-index: 3;
			// max-width: 240px;
			// width: 100%;
			&.animate-btn {
				max-width: 200px;
			}
		}
		// .link-view-profile{
		// 	font-family: $fontfamilyheaders;
		// 	font-size: 14px;
		// 	line-height: 24px;
		// 	text-decoration: none;
		// 	cursor:pointer;
		// 	color: #fff;
		// 	text-transform: uppercase;
		// 	margin: 0;
		// 	width: 100%;
		// 	height: 100%;
		// 	display: flex;
		// 	flex-direction: row;
		// 	align-items: center;
		// 	justify-content: space-around;
		// }
	}
	.link-view-profile,
	.choose-lady-profile {
		font-family: $fontfamilyheaders;
		font-size: 14px;
		line-height: 24px;
		text-decoration: none;
		cursor:pointer;
		color: #fff;
		text-transform: uppercase;
		margin: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-around;
	}
	.view-profile-mob{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		font-family: $fontfamilyheaders;
		font-size: 14px;
		line-height: 24px;
		text-decoration: none;
		cursor:pointer;
		color: #fff;
		text-transform: uppercase;
		margin: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		span {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

}

.row-circle-name-date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;
    align-items: center;
    border-top: 1px solid $color-link-water;
    border-bottom: 1px solid $color-link-water;
    .circle-girl-photo-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
		position: relative;
		text-decoration: none;
		color: #000;
        span {
            &.birthday{
                background-color: #fff;
                position: absolute;
                border-radius: 50%;
                top: 30px;
                left: 40px;
                font-size: 19px;
                width: 30px;
                height: 30px;
                padding: 4px 5px;
                color: $color-milano-red;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
            }
        }
        .info-date-name{
            a{
                text-decoration: none;
				color: $global-color-font;
				display: block;
				&.history {
					margin-top: 11px;
					text-decoration: underline;
					&:hover{
						text-decoration: none;
					}
				}
            }
			.disable {
				color: $color-grey;
				text-decoration: line-through;
			}
            .date{
                color: $color-grey;
            }
        }
    }
    &.new {
        background-color: $color-solitude;
    }
    @media(max-width: $mobile) {
        padding: 20px 16px;
    }
}

// звездочка для добавления в фаворитные 
.girl-favorite-star-wrap {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 50px;
    height: 50px;
    z-index: 99;

    .girl-favorite-star-innerwrap {
        width: 100%;
        height: 100%;

        .change-girl-star {
            width: 100%;
            height: 100%;
            font-size: 24px;
            line-height: 50px;
            text-align: center;
            cursor: pointer;

            .uadicon-favorites,
            // .uadicon-favorites-added,
            .uadicon-favorites-filled,
            // .uadicon-favorites-subsribed,
            .uadicon-favorites-subscribed {
                display: none;
            }

            svg {
                pointer-events: none;

                &.uadicon-favorites {
                    color: #fff;
                    fill: #fff;
                    -webkit-filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, .5));
                    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, .5));
                }
            }

            // DEPRECATED added, subscribed and deleted
            // &.added .uadicon-favorites-added,
            // &.favorite .uadicon-favorites-added, 
            &.added .uadicon-favorites-filled,
            &.favorite .uadicon-favorites-filled{
                display: inline-block;
            }

            &.deleted .uadicon-favorites,
            &.default .uadicon-favorites {
                display: inline-block;
            }

            // &.subscribed .uadicon-favorites-subsribed,
            // &.sweetheart .uadicon-favorites-subsribed,
            &.subscribed .uadicon-favorites-subscribed,
            &.sweetheart .uadicon-favorites-subscribed {
                display: inline-block;
            }
        }
    }
}

.girl-birthday-wrap {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 50px;
    height: 50px;
    z-index: 1;

    .girl-birthday-innerwrap {
        width: 100%;
        height: 100%;

        .girl-birthday {
            display: inline-block;
            width: 100%;
            height: 100%;
            color: $color-solitude;
            font-size: 32px;
            line-height: 50px;
            text-align: center;
            cursor: pointer;

            &.hide {
                display: none;
            }
        }
    }
}

// style from lady-card

.block-girl-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;

    @media(max-width: $mobile) {
        max-width: 280px;
        margin: 0 auto;
    }

    .block-title-girl {
        position: relative;
        @media(max-width: $mobile) {
            display: flex;
            align-items: inherit;
            justify-content: space-between;
            width: 100%;
        }

        h3,
        h1,
        h2 {
            margin-top: 0px;
            margin-right: 0;
            margin-bottom: 5px;
            margin-left: 0;
            padding: 0;
            color: $color-milano-red;
            font-family: $fontfamily;
            font-size: 36px;
            font-style: normal;
            font-weight: normal;
            line-height: 42px;

            @media(max-width: $mobile) {
                font-size: 24px;
                line-height: 30px;
            }

            sup {
                position: absolute;
                top: -3px;
                color: $global-color-font;
                font-size: 16px;
                line-height: 24px;

                @media(max-width: $mobile) {
                    font-size: 12px;
                    line-height: 18px;
                }
            }

            a {
                position: relative;
                color: $color-milano-red;
                font-size: 36px;
                line-height: 42px;
                text-decoration: none;

                @media(max-width: $mobile) {
                    font-size: 24px;
                    line-height: 30px;
                }

                sup {
                    position: absolute;
                    top: -3px;
                    color: $global-color-font;
                    font-size: 16px;
                    line-height: 24px;

                    @media(max-width: $mobile) {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
            }
        }

        .girl-year-old {
            margin-bottom: 12px;
            color: #000;
            font-size: 16px;
            line-height: 24px;
            @media(max-width: $mobile){
                width: 22%;
            }
            a {
                color: #000;
            }
        }
    }
}

.container-videochat {
	.chat-video .girl-photo-wrap {
		min-height: 360px;
		@media(max-width: $mobile) {
			min-height: 420px;
		}
	}
}

.video-spinner {
	width: 60px;
	height: 60px;
	border: 2px solid #f3f3f3;
	border-top: 3px solid #000;
	border-radius: 100%;
	position: absolute;
	top:0;
	bottom:0;
	left:0;
	right: 0;
	margin: auto;
	animation: spin 1s infinite linear;

	@keyframes spin {
		from{
			transform: rotate(0deg);
		}to{
			transform: rotate(360deg);
		}
	}
}

.video-progress {
	width: 100%;
	height: 5px;
	position: absolute;
	top:0;
	left:0;
	background-color: #45B61D;
}