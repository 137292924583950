@font-face {
    font-family: 'Sanchez';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sanchez/v8/Ycm2sZJORluHnXbIfmlR_Q.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

*:focus {
    outline: 0 none;
}
* {
    &::selection {
        background: $color-milano-red;
        /* Safari */
        color: #fff;
    }
    &::-moz-selection {
        background: $color-milano-red;
        /* Firefox */
        color: #fff;
    }
}
.webcam_up_img{
    max-width: 100%;
}
.show-safari-bar {
    height: 100%;
    body {
        position: fixed !important;
        overflow: hidden !important;
        width: 100% !important;
        top: 0 !important;
    }
}

.show-safari-bar,
.mob-modal {
    @media (max-width: $mobile) {
        .reactpopup-outer.invitation {
            position: fixed !important;
            height: 100% !important;
            width: 100% !important;
            overflow: auto !important;
        }
        .chat-now-outer{
            max-height: 100%;
            overflow: auto;
            // .chat-modal{
            //     padding-bottom: 157px;
            // }
        }
        .rating-popup, .reject-popup {
            position: fixed;
            height: 100%;
            width: 100%;
            overflow: hidden;
            min-height: auto;
        }
        .reject-block,
        .rating-chat-window{
            // position: fixed !important;
            height: 100% !important;
            // width: 100% !important;
            // overflow: auto !important;
            // .button-fixed{
            //     position: fixed;
            //     bottom: 0;
            //     left: 0;
            //     margin: 0;
            // }
        }
        .reject-form {
            max-height: calc(100% - 240px);
        }
    }
}

.stories-viewer {
    height: 100vh !important;
    body {
        overflow: hidden !important;
        width: 100% !important;
        top: 0 !important;
        @media (max-width: 768px){
            // position: fixed !important;
        }
    }   
}

body {
    background-color: $color-solitude;
    color: $global-color-font; // обсудить с дизайнером
    font-family: $fontfamily;
    &.mobile-chat-owerflow {
        overflow: hidden;
    }

    &.marketing-45 {
        @media screen and (min-width: 768px) {
            background: url('https://s3.uadreams.com/content/marketing/45/20230622032602_Sguf.png') right top no-repeat fixed;
            background-color: $color-solitude;
            background-size: cover;
        }
    }
}
body.body-no-croll {
    overflow: hidden;
}
.debug {
    .page-content {
        overflow: initial;
    }
}

.hide {
    display: none;
}
.hide-important {
    display: none !important;

}

.secondary-text {
	font-size: .87em;
	line-height: 1.45em;
}

.container {
    width: 100%;
    margin: 0 auto;
    @media(min-width: $tablet+1) {
        max-width: 1000px;
    }
}
.page-content {
    transition: box-shadow .3s cubic-bezier(.25, .8, .25, 1);
    box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
    position: relative;
    padding: 20px;
    border-radius: 3px;
    background: #fff;
    overflow: hidden;
    // margin-bottom: 2px;
    @media (max-width: $mobile) {
        padding: 16px;
    }
    &.without-paddings {
        padding: 0;
    }
    &.static-pages {
        box-shadow: none;
    }
    &.girl-birthday {
        box-shadow: none;
    }
    &.videochat {
        display: flex;
        position: relative;
        overflow: visible;
        @media(max-width: $mobile) {
            flex-direction: column;
        }
        @media(min-width: $mobile+1) {
            &:after {
                position: absolute;
                top: 0;
                left: 0;
                width: 220px;
                height: 100%;
                background: #551547;
                content: "";
                z-index: 1;
            }
        }
    }
    .top-line-banner {
        display: block;
        position: relative;
        &.basket {
            @media(max-width: $mobile) {
                margin-top: 100px;
            }
        }
        img {
            width: 100%;
        }
        .top-line-text {
            display: flex;
            justify-content: space-between;
            align-items:center;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            padding: 0 14px 0 10px;
            color: #fff;
            font-family: $fontfamilyheaders;
            z-index: 10;
            @media(max-width: $mobile) {
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
            }
            .top-line-text-title {
                font-size: 18px;
                font-family: 'Sanchez';
                &.small {
                    font-size: 14px;
                }
                @media(max-width: $mobile) {
                    font-size: 16px;
                    font-family: Montserrat;
                    font-weight: 500;
                    &.small {
                        font-size: 16px;
                    }
                    .underline-mob {
                        text-decoration: underline;
                    }
                }
            }
            .top-line-text-time {
                font-size: 22px;
                font-weight: 500;
                @media(max-width: $mobile) {
                    margin-top: 5px;
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }
    }
}

.uadicon {
    display: inline-block;
    width: 1em;
    height: 1em;
    transition: all .24s;
    fill: currentColor;
    stroke: currentColor;
    stroke-width: 0;
    &.preview-all {
        width: 4em;
        height: 4em;
    }
    &.in-button-icon {
        width: 12px;
        height: 12px;
    }
    &.like-button-icon {
        width: 24px;
        height: 24px;
    }
}
.hide-from-desc {
    display: none !important;
    @media(max-width: $mobile) {
        display: block !important;
    }
}
@media(min-width: $tablet+1) {
    .show-only-from-tablet {
        display: none !important;
    }
}
@media(min-width: $mobile+1) {
    .show-only-from-mobile {
        display: none !important;
    }
}
@media(max-width: $tablet) {
    .hide-from-tablet {
        display: none !important;
    }
    .disable-padding-from-tablet {
        padding: 0 !important;
    }
}
@media(max-width: $mobile) {
    .hide-from-mobile {
        display: none !important;
    }
}
.uadicon-favorites-filled {
    fill: #fbb802;
}
.uadicon-favorites-subscribed{
    fill: #e74c3c;
}
// seo controls ?????
.seo-control {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    width: 55px;
    height: 40px;
    background-color: rgba(255, 0, 0, .3);
    color: #fff;
    cursor: pointer;
    opacity: .5;
    z-index: 1000;
    &:before {
        position: absolute;
        width: 100%;
        text-align: center;
        content: 'SEO';
    }
    .seo-control:after {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        content: '';
    }
    &.turnedon:after {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        content: 'hlght';
    }
    &.off:after {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        content: 'trnOn';
    }
    &.highlighted:after {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        content: 'trnOff';
    }
}

.image-loading-block {
    width: 100%;
    height: 100%;
    background: #ebebeb;
	//background: linear-gradient(112deg, rgba(241,243,245,1) 0%, rgba(241,243,245,1) 40%, rgba(247,250,252,1) 50%, rgba(241,243,245,1) 60%, rgba(241,243,245,1) 100%);
	//background-size: 400% 400%;
	//animation: gradient 1.5s ease infinite;
}

.block-quick-support {
    display: none;
    position: fixed;
    bottom: 70px;
    right: 50px;
    z-index: 1001;
    .circle {
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        padding-top: 4px;
        background: $color-night-rider;
        color: white;
        border-radius: 50%;
        font-family: $fontfamilyheaders;
        font-size: 24px;
        font-weight: 500;
        cursor: pointer;
    }
    .informer {
        position: absolute;
        top: -35px;
        left: -30px;
        width: 100px;
        padding: 5px;
        background: $color-night-rider;
        font-size: 12px;
        line-height: 18px;
        color: white;
        border-radius: 3px;
        text-align: center;
        opacity: 0;
        transition: .5s;
        &.active {
            opacity: 1;
        }
        &::after {
            content: ' ';
            position: absolute;
            bottom: -10px;
            left: calc(50% - 3px);
            width: 5px;
            height: 5px;
            border: 5px solid transparent;
            border-top: 5px solid $color-night-rider;
        }
    }
}

.stories-viewer .block-hh-push{
    display: none!important;
}

.block-hh-push {
    position: fixed;
    bottom: 20px;
    right: 10px;
    top: auto;
    z-index: 1002;
    animation: show 1s;
    
    @keyframes show {
        from {
            right: -100%;
        }
        to {
            right: 10px;
        }
    }

    .content {
        position: absolute;
        top: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 24px 24px 32px;
        color: #fff;
        text-transform: uppercase;
        .text {
            font-family: $fontfamilyheaders;
            font-weight: 500;
            font-size: 32px;
            line-height: 40px;
            .chat {
                font-weight: 600;
                font-size: 48px;
                line-height: 60px;  
            }
        }
        .button-happy-hour {
            max-width: none;
        }
    }
    .push-close {
        width: 32px;
        height: 32px;
        position: absolute;
        right: 20px;
        top: 20px;
        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        .cross {
            padding: 15px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            &::after,
            &::before {
                background: #fff;
            }
        }
    }
}

.chat_informer_main {
    display: none;
}

.block-bottom-ladies {
    transform: translate(0, 700px);
}

.popup-wrapper-auth{
	display: none;
}

// стикеры и смайлы для чата

// .smile-block .menu {   position: absolute;   top: 0;   left: 0;   right:0;   height: 55px; white-space: nowrap; overflow-x: auto; overflow-y: hidden; }
// .smile-block .menu ul{ background-color: #f1f1f1; height: 55px; margin: 0; padding: 0;} 
// .smile-block .menu li.active{ background-color: white;}
// .smile-block .menu li{ width: 55px;	height: 55px;display: inline-block;	}
// .smile-block .menu img{	display: block;	margin: 0 auto;	}
// .smile-block .folders{top:55px; bottom:0;  left: 0; overflow: hidden; position: absolute; right: 0;}
// .smile-block .folders .folder{ position: absolute;display: none;}
// .smile-block .folders .folder.active{ display: block;}
// .ws_main.desctop .smile-block .folders{ bottom: 55px;top:0; }
// .ws_main.desctop .smile-block .menu {bottom: 0; top: auto;}
// .winks-nano{ width: 100%; height: 100%;}
// .wink-item { float: left; width: 135px;}
// .wink-item img{ width: 135px;}
// .smile .wink-item, .smile .wink-item img{
// 	width:auto;
// }

// .gallery-sub-html,
// .gallery-html {
//     display: none;
// }

.loading {
    position: relative;
    cursor: wait;
    &:before {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 125px;
        height: 33px;
        transform: translate(-50%, -50%);
        background-color: #fff;
        content: '';
        z-index: 3;
        mask: url('/inc/images/icons/uadreams_icon.svg') no-repeat 50% 50%;
        -webkit-mask: url('/inc/images/icons/uadreams_icon.svg') no-repeat 50% 50%;
    }
    &.gray {
        &:before {
            background-color: $color-solitude;
        }
    } 
}
.video-gallery-poster {
    height: auto;
    width: 100%;
    position: relative;
    cursor: pointer;
    @media(max-width: $mobile) {
        height: auto;
    }
    .image-loading-item {
        margin: 0 auto;
    }
    img {
        width: 100%;
    }
}
.button-play-video {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg {
        color: #fff;
        fill: #fff;
        font-size: 100px;
    }
}

.posts_languages_menu {
    display: block;
    margin-bottom: 15px;
    text-align: right;

    .current_lang {
        color: $global-color-font;
        text-decoration: none;
    }
}

.left-menu-wrap {
    flex: 0 0 220px;
    width: 220px;
    font-family: $fontfamilyheaders;
    @media(max-width: $mobile) {
        flex: 1 1 auto;
        width: 100%;
    }
}

.banner-wrapper {
    .banner-item {
        display: none;
        opacity: 0;
        transition: all .3s;
        transform: translate(10px,0);
        &:not([href]) {
            cursor: default;
        }
        &.active {
            display: block;
            opacity: 1;
            transform: translate(0,0);
        }
        img {
            width: 100%;
            image-rendering: -moz-crisp-edges;
            image-rendering: -webkit-optimize-contrast;
        }
    }
}

.video-js{
    display: block;
    max-width: 100%;
}

.serach-rel-fav-girl {
    color: $color-grey;
    text-decoration: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 14px;
    z-index: 2;
}

.rel-fav-carousel-wrap {
    position: relative;
    .swiper-button-next,
    .swiper-button-prev {
        display: flex;
        justify-content: center;
        align-items: center;
        top: -45px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: none;
        margin-top: 0px;
        border: 1px solid #858585;

        &::after {
            display: none;
        }

        .uadicon {
            font-size: 14px;
            color: #000;
        }
    }

    .swiper-button-next {
        right: 0px;
    }

    .swiper-button-prev {
        right: 46px;
        left: auto;
    }
    .swiper-slide {
        max-width: 240px;

        .lady-wrap {
            width: 100%;
            height: auto;
        }
    }
}

#reCaptchaWrap{
    visibility: hidden;
    .grecaptcha-badge{
        z-index: 99999;
    }
}

.grecaptcha-badge{
    display: none!important;
}