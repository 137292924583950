.breadcrumbs-and-time-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px 10px 10px 10px;
    .ukraine-time {
        width: auto;
        color: $color-grey;
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: 300;
        text-align: right;
    }
    @media(max-width: $mobile) {
        display: none !important;
    }
    .banner-ie {
        display: none;
        flex: 1 1 auto;
        text-align: center;
        padding: 10px;
        color: #fff;
        background-color: #7aafff;
        margin: 10px -10px 0 -10px;
        text-transform: uppercase;
        font-family: $fontfamilyheaders;
        text-decoration: none;
        &:hover {
            background-color: darken(#7aafff, 5%);
        }
        img {
            float: right;
            justify-self: flex-end;
            width: 23px;
            height: 23px;
        }
    }
}

.breadcrumbs {
    width: 70%;
    .site_breadcrumb {
        ol {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                padding-right: 5px;
                display: inline;
                color: #000;
                font-size: 14px;
                font-weight: 300;
                &:after {
                    content: '-';
                }
                &:last-child:after {
                    display: none;
                }
            }
        }
    }
    span {
        padding-right: 5px;
        color: #858585;
        font-size: 14px;
        font-weight: 300;
    }
    a{
        text-decoration: underline;
        color: #000;
        span {
            color: #000;
        }
    }
    
}
