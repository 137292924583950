.badge {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 11px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    &.happy-hour {
        background: linear-gradient(98.69deg, #6E36DF 0%, #9F3580 100%);
    }
}

@each $key, $value in $theme-colors {
    .badge-#{$key} {
        background-color: $value;
        color: #fff;
    }
}
